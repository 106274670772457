<template>
  <div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-4">
					<div class="header d-flex flex-column">
						<h3 class="secondary-text text-lg-left form-title">Wachtwoord</h3>
            <p class="body-1 ma-0">Wijzig hier je wachtwoord.</p>
          </div>

          <v-divider class="mt-5"></v-divider>

          <div class="pl-10">
            <v-form ref="form" v-model="disableSave">
              <div class="mt-4 d-flex flex-row align-start py-4">
                <p class="ma-0 profile-item body-1 grey--text text--lighten-1">Huidig wachtwoord</p>
                <v-text-field
                  class="profile-item"
                  outlined
                  :rules="[...rules.first]"

                  v-model="currentPassword"
                  :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showCurrent ? 'text' : 'password'"
                  @click:append="showCurrent = !showCurrent"
                ></v-text-field>
              </div>

              <div class="d-flex flex-row align-start pb-4">
                <p class="ma-0 profile-item body-1 grey--text text--lighten-1">Nieuw wachtwoord</p>
                <v-text-field
                  class="profile-item"
                  outlined

                  v-model="values[0]"
                  :rules="rules.second.concat(rules.secondEmpty, rules.secondLength)"
                  :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNew ? 'text' : 'password'"
                  @click:append="showNew = !showNew"
                ></v-text-field>
              </div>

              <div class="d-flex flex-row align-start pb-4">
                <p class="ma-0 profile-item body-1 grey--text text--lighten-1">Bevestig nieuw wachtwoord</p>
                <v-text-field
                  class="profile-item"
                  outlined

                  v-model="values[1]"
                  :rules="rules.third.concat(rules.thirdEmpty, rules.thirdLength)"
                  :append-icon="confirmNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="confirmNew ? 'text' : 'password'"
                  @click:append="confirmNew = !confirmNew"
                ></v-text-field>
              </div>
            </v-form>

              
            <div class=" mb-2 d-flex justify-end align-center">
              <v-btn text class="ml-4" aria-label="Login" :to="{ path: '/forgot-password'}">Wachtwoord vergeten</v-btn>

              <v-btn :disabled="!disableSave" @click="saveNewPass" color="blue-grey" class="white--text ml-4" aria-label="Wijzig wachtwoord">Wachtwoord opslaan</v-btn>
            </div>
          </div>

        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import api from '../../../../services/backendApi';

  export default {
    name: 'password',
    props: {
      
		},

    data() {
      return {
        disableSave: false,
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        values: [
          this.newPassword,
          this.confirmPassword,
        ],
        showCurrent: false,
        showNew: false,
        confirmNew: false,
      }
    },

    created() {

		},

    mounted() {
    },

    computed: {
      rules() {
        return {
          first: [(value) => !!value || 'Huidig wachtwoord mag niet leeg zijn.'], 
          second: [(value) => value !== this.currentPassword || 'Nieuw wachtwoord is hetzelfde als het huidige wachtwoord.'], 
          secondEmpty: [(value) => !!value || 'Nieuw wachtwoord mag niet leeg zijn'],
          secondLength: [(value) => (value || '').length >= 6 || 'Moet minstens 6 tekens bevatten'],
          third: [(value) => value === this.values[0] || 'Wachtwoord komt niet overeen.'],
          thirdEmpty: [(value) => !!value || 'Bevestiging nieuw wachtwoord mag niet leeg zijn'],
          thirdLength: [(value) => (value || '').length >= 6 || 'Moet minstens 6 tekens bevatten'],
        };
      }
    },

    watch: {
      values: {
        immediate: true,
        async handler() {
          await this.$nextTick();
          this.$refs.form.validate();
        }
      }
    },

    methods: {
      saveNewPass() {
        this.$emit('save',{currentPassword: this.currentPassword,newPassword:this.values[0]})

        this.currentPassword = '',
        this.values[0] = '',
        this.values[1] = ''
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    margin-left: 250px;
    margin-right: 250px;
    max-width: calc(100vw - 300px);
  }

  .form-title {
    display: flex;
  }

  .profile {
    &-item {
      width: 50%;
    }
  }
</style>