import api from '../../../services/backendApi';

const tenantsUrl = '/tenants';
const stayingUrl = '/staying';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getTenants(filter) {
    return api.get(`${tenantsUrl}`, { filter }, config);
  },
  getTenantDetail(id) {
    return api.get(`${tenantsUrl}/${id}`, config);
  },
  mailAll() {
    return api.post(`/mailing/staying/all`, config);
  },
  mailPerson(mails) {
    return api.post(`/mailing/staying`, { mails }, config);
  },
  updateStayStatus(tenant) {
    return api.patch(`${stayingUrl}/stay-status/${tenant[0].tenantId}`, { stayStatus: tenant[0].tenantValue, tenant: tenant[0] }, config);
  },
  updateStayRemarks(tenant) {
    return api.patch(`${stayingUrl}/stay-remarks/${tenant._id}`, { comment: tenant.comment, commentStatus: tenant.commentStatus, commentDate: tenant.commentDate, commentCurrentUser: tenant.commentCurrentUser }, config);
  },
  removeStayRemarks(tenantId, remarkId) {
    return api.delete(`${stayingUrl}/stay-remarks/${tenantId}/remark/${remarkId}`, config);
  },
  initialStayStatus() {
    return api.get(`${stayingUrl}/set-stay-status/`, config);
  },
  downloadStayers(params) {
    return api.get(`/downloads/follow-up/stayers`, { config, params })
  },
  mailStayersSelection(mailData) {
    return api.post(`/mailing/follow-up-stayers/selection`, { ...mailData }, config);
  },
};
