export const MODULE_NAME = 'staying';

export const ACTIONS = {
	GET_ALL_TENANTS: `${MODULE_NAME}/GET_ALL_TENANTS`,
	GET_DETAIL_TENANT: `${MODULE_NAME}/GET_DETAIL_TENANT`,
	MAIL_ALL: `${MODULE_NAME}/MAIL_ALL`,
	MAIL_PERSON: `${MODULE_NAME}/MAIL_PERSON`,
	SET_STAYSTATUS_CHECK: `${MODULE_NAME}/SET_STAYSTATUS_CHECK`,
	UPDATE_REMARKS: `${MODULE_NAME}/UPDATE_REMARKS`,
	REMOVE_REMARK: `${MODULE_NAME}/REMOVE_REMARK`,
	SET_INITIAL_STAY_STATUS: `${MODULE_NAME}/SET_INITIAL_STAY_STATUS`,
	DOWNLOAD: `${MODULE_NAME}/DOWNLOAD`,
	MAIL_STAYERS_SELECTION: `${MODULE_NAME}/MAIL_STAYERS_SELECTION`
};


export const MUTATIONS = {
	SET_ALL_TENANTS: `${MODULE_NAME}/SET_ALL_TENANTS`,
	SET_DETAIL_TENANT: `${MODULE_NAME}/SET_DETAIL_TENANT`,
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
