import { isNil, isEmpty, update } from 'ramda';
import api from '@/services/backendApi';


const baseUrl = `${process.env.VUE_APP_API_URL}/tenants`;
const contractBaseUrl = `${process.env.VUE_APP_API_URL}/contracts`;
const config = {
  headers: {
    'Content-Type': 'application/json'
  },
};

export const tenantService = {
    getTenants:(params)=>{
        return api.get(
            `${baseUrl}`,
            { config, params },
          );
    },
    getOneTenant:(tenantId)=>{
      return api.get(
          `${baseUrl}\\${tenantId}`,
          {config}
      );
    },
    getContractsForTenant:(tenantId)=>{
      return api.get(
        `${baseUrl}\\${tenantId}\\contracts`,
          {config}
      );
    },
    getContractForTenant:(tenantId,contractId)=>{
      return api.get(
        `${baseUrl}\\${tenantId}\\contracts\\${contractId}`,
          {config}
      );
    }

}