<template>
  <div>
    <v-layout row wrap>
      <v-flex md12 xs12 class="">
        <v-card class="card pa-4">
          <!-- The following part is the page header -->
          <div class="header-container">
            <div class="header d-flex justify-space-between align-center">
              <div>
                <h1 class="secondary-text text-lg-left form-title">Units</h1>
                <!-- <h4 class="text-sm-left grey--text">Te huur</h4> -->
              </div>
              <div>
                <div class="mt-3 d-flex justify-end">
                  <v-btn
                    x-large
                    color="blue-grey"
                    class="white--text"
                    @click="download"
                  >
                    <v-icon small left dark>mdi-file-excel</v-icon>
                    Download Units
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <v-card flat :elevation="0">
            <v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
                :searchLabel="'Zoeken naar Project / Kamer Type / Nummer / Stad'"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$rentList'"
                @filterData="setFilterOnList"
                :showFilters="true"
								:showSearchBar="true"
                >
              </filter-data-dialog-component>
            </v-card-title>
            <!-- The following part is the data-table -->
            <v-data-table
              item-key="tableKey"
              :headers="headers"
              :menu="menu"
              :items="units"
              :options.sync="options"
              :server-items-length="totalCount"
              :loading="isLoading"
              :footer-props="{'items-per-page-options': [5, 10, 15]
              }"
              @update:page="setSkip"
              @update:items-per-page="setLimit"
              @update:sort-by="setSort"
              @update:sort-desc="setSort"
            >

              <!-- The following template will provide an override for the availableFromDate cells -->
              <template v-slot:item.betweenRange="{ item }">
                <td :title="item.availableFromTo" class="text-xs-left">
                  {{ item.betweenRange | formatDate }}
                </td>
              </template>


              <!-- The following template will provide an override for the roomNumber cells and provide a link to the room detail -->
              <template v-slot:item.unitName="{ item }">
                <td class="text-xs-left">
                  <router-link
                    :to="{ name: 'unitDetail', params: { id: item._id } }"
                    target="_blank"
                  >
                    {{ item.unitName }}
                  </router-link>
                </td>
              </template>

              <!-- The following template will provide an override for the statuses cell and provide a link to the room detail -->
              <template v-slot:item.status="{ item }">
                <v-select
                  :disabled="!canEdit"
                  v-model="item.status"
                  :items="unitStatuses"
                  dense
                  solo
                  hide-details
                  item-text="name"
                  item-value="_id"
                  @change="rentStatus($event, item._id,item.rentableUnitId, item), clickedStatus = true"
                />
              </template>


              <!-- The following template will provide an override for the comments cells and provide an icon-button to open the dailog -->
              <template v-slot:item.unitRemarks="{ item }">
                <v-icon color="grey" @click="openCommentDialog(item), clickedStatus = false">
                  {{
                    item.unitRemarks.length !== 0
                      ? "mdi-chat"
                      : "mdi-chat-outline"
                  }}
                </v-icon>
              </template>

              <!-- The following template will provide an override for the comments cells and provide an rentalcode editable field -->
              <template v-slot:item.rentalCode="{ item }">
                <td style="display:flex; align-items:center;">
                  <v-text-field v-if="editing === item" style="font-size:0.875rem;min-width:120px;padding:5px 0;"
                    :disabled="!canEdit"
                    v-model="editing.rentalCode"
                    full-width autofocus filled dense hide-details
                    prepend-inner-icon="mdi-check"
                    @keyup.esc="cancelEdit(item)"
                    @keyup.enter="editRentalCode(item)"
                    @click:prepend-inner="editRentalCode(item)">
                  </v-text-field>
                  <div style="min-width:100px; display:flex;align-items:center;" v-else>
                    <v-btn :disabled="!canEdit" small icon @click.stop.prevent="editRentalCode(item)">
                      <v-icon small>mdi-pencil-circle-outline</v-icon>
                    </v-btn>
                    {{item.rentalCode}}
                  </div>
                </td>
              </template>
              <!-- The following template will provide a custom fallback when no units are provided or found -->
              <template slot="no-data">
                <v-alert :value="true" color="grey" icon="mdi-warn">
                  Sorry, geen units gevonden
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- The following dialog will allow for a user to add a comment to a selected unit. -->
    <v-dialog
      v-model="currentComments.show"
      @click:outside="resetCommentsDialog"
      max-width="700px"
    >
      <comments-dialog
        v-if="currentComments.show"
        :comments="currentComments.comments"
        :title="currentComments.title"
        :status="currentComments.status"
        @add="addUnitComment"
        @delete="removeUnitComment"
        :canEdit="canEdit"
      ></comments-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { isNil, isEmpty, pathOr } from "ramda";
import debounce from "lodash.debounce";

import { ACTIONS as TOAST_ACTIONS } from "../../../../components/toast/toast.constants.js";
import CommentsDialog from "../../shared/components/comments-dialog";
import FilterDataDialogComponent from '@components/data-filter/filter-data-dialog.component.vue';

import { GETTERS } from '@/account/account.constants';
import { RENT_ACTIONS } from "../store/rent.store";
import { mapUnits, parseFilterData } from "../helpers";

import {
  INITIAL_FILTERS,
  RENT_LIST_HEADERS,
  FILTER_SORT_OPTIONS,
  INITIAL_COMMENTS,
  ADJUSTABLE_UNIT_STATUSES,
} from "./rent-list.const";
import { DATA_FILTERS } from '@/components/data-filter/const.js';

const RENT_ACCEPTED_FILTERS = [
  DATA_FILTERS.PROJECTS,
  DATA_FILTERS.UNITS,
  DATA_FILTERS.UNIT_TYPES,
  DATA_FILTERS.AVAILABLE_RANGE,
  DATA_FILTERS.CITIES,
  DATA_FILTERS.RENT_STATUSES
  ];

export default {
  components: {
    CommentsDialog,
    FilterDataDialogComponent
  },
  /**
   * The data part consists of:
   * - search: the property mapped to the search input. Initial value is an empty string.
   * - options: the property mapped to the options property of the data-table. Its value will be set by the table.
   * - filters: the filters available on the table. This object is initially empty because it will be build dynamically (see line 54).
   * - headers: the data-table headers, imported from ./rent-list.const.js
   * - currentFilters: the current filters and sorting. These are the params that will be clicked to the API when units are fetched.
   * - currentCommentsId: the id of the current selected comments and the units they belong to + whether or not to show the comments dialog.
   * - updatedUnitStatus: the selected status for the unit
   */
  data: () => ({
    acceptedFilters: RENT_ACCEPTED_FILTERS,
    filterParams:{},
    clickedStatus: false,
    commentStatus: null,
    dateArray: [],
    date: null,
    search: "",
    options: {},
    filters: {},
    headers: RENT_LIST_HEADERS,
    currentFilters: INITIAL_FILTERS,
    unitStatuses: ADJUSTABLE_UNIT_STATUSES,
    currentCommentsId: undefined,
    updatedUnitStatus: undefined,
    editing: null,
    origItem:null,

    dateValues: {
      'betweenRange': 'betweenRange',
    },
    dateLabels: {
      'betweenRange': 'Selecteer periode',
    },
    menu: {
			'betweenRange': false,
		},
  }),
  /**
   * The watch part consists of:
   * - search: this is a watch on the search property. When it is triggered it will trigger the getUnitsForRent method and perform a search.
   *   There is a debounce of 1000ms set to the search with lodash.debounce. This is to avoid a request for each change in the input.
   * - error: this is a watch on the error property (see computed -> mapState). When it is triggered it will trigger a new toast message
   *   that will let the user know that something went wrong when fetching data.
   */
  watch: {
    error: function () {
      if (!isNil(this.error)) {
        this.makeToast({
          message: "Er ging iets fout bij het ophalen van de data.",
          type: "error",
        });
      }
    },
  },
  /**
   * The mounted lifecycle will perform two actions:
   * 1. It will get the filter-data through the `getFilters` method.
   * 2. It will get the initial units through the `searchUnitsForRent` method.
   *
   * We perform these actions in mounted to have the data available as soon as possible.
   */
  mounted: function () {
    this.getFilters();
    // this.searchUnitsForRent();
  },
  /**
   * The computed part consists of:
   * - mapped state properties from the rent store:
   *   - filterData: this property will contain the filter-data from the store. It will be mapped with the `parseFilterData` helper.
   *   - units: this property will contain the units from the store. It will be mapped with the `mapUnits` helper.
   *   - isLoading: this property contains the isLoading boolean from the store. It will indicate if units are being fetched.
   *   - totalCount: this property contains the totalCount number from the store. It will indicate the total amount of units (on the server).
   *   - error: this property contains errors that are set in the state when requests fail.
   *   - currentComments: this computed property uses `currentCommentsId` to return a reactive object representing which comments should be
   *     shown in the dialog. The main advantage of this over adding this to the local state (data) is the fact that making this property
   *     computed will live-update the content of the comment-dialog window.
   */
  computed: {
    ...mapState({
      units: (state) => mapUnits(pathOr([], ["rent", "units"], state)),
      isLoading: (state) => pathOr(false, ["rent", "isLoading"], state),
      totalCount: (state) => pathOr(0, ["rent", "totalCount"], state),
      error: (state) => pathOr(null, ["rent", "error"], state),
    }),

    ...mapGetters({
			getRights: GETTERS.RIGHTS
		}),

    canEdit() {
      return this.getRights.FOLLOW_UP_RENT.toLowerCase() === 'edit'
    },

    currentComments() {
      if (!this.currentCommentsId) return INITIAL_COMMENTS;

      const unit = this.units.find(
        (unit) => unit._id === this.currentCommentsId
      );

      if (this.updatedUnitStatus === "ON_HOLD" && this.clickedStatus) {
        return unit
          ? {
              comments: unit.unitRemarks,
              unit: unit._id,
              rentableUnitId: unit.rentableUnitId,
              show: true,
              title: 'Geef een opmerking over de ON HOLD status',
              status: {
                statusValue: this.updatedUnitStatus,
                clickedStatus: this.clickedStatus
              }
            }
          : INITIAL_COMMENTS;
      } else {
        return unit
          ? {
              comments: unit.unitRemarks,
              unit: unit._id,
              rentableUnitId: unit.rentableUnitId,
              show: true,
              title: null,
              status: {
                statusValue: unit.status,
                clickedStatus: this.clickedStatus
              }
            }
          : INITIAL_COMMENTS;
      }
    },
  },
  /**
   * The methods part consists of:
   * - mapped actions from the store:
   *   - getFilters: this action will trigger a fetch for filter-data and store them.
   *   - getUnitsForRent: this action will trigger a fetch for units and store them. It will set isLoading to true and false.
   *   - addComment: this action will trigger a request to add a comment which will re-fetch the units with the current filters after it's done.
   *   - removeComment: this action will trigger a request to remove a comment which will re-fetch the units with the current filters after it's done.
   *   - makeToast: this action will trigger a new toast notification.
   * - searchUnitsForRent: this method will trigger the `getUnitsForRent` method with the currentFilters.
   * - resetSkip: this method will reset the skip to 0 on both the currentFilters as the data-table options.
   * - setSkip: this method will be triggered by the data-table on @update:page and set the skip of the `currentFilters` and trigger the `searchUnitsForRent` method with those updated `currentFilters`.
   * - setLimit: this method will be triggered by the data-table on @update:items-per-page and set the limit of the `currentFilters` and trigger the `searchUnitsForRent` method with those updated `currentFilters`.
   * - setSort: this method will be triggered by the data-table on @update:sort-by or @update:sort-desc and set the selected sorting in the `currentFilters` and trigger the `searchUnitsForRent` method with those updated `currentFilters`.
   * - setFilter: this method will be triggered by the filters (select) on @change and set the selected filtering in the `currentFilters` and trigger the `searchUnitsForRent` method with those updated `currentFilters`.
   * - openCommentDialog: this method will be triggered when an item in the remarks column is clicked. It will set the `currentCommentsId` property and open the comments dialog.
   * - addUnitComment: this method will trigger the `addComment` action with the new comment text, unitId and the current filters.
   * - removeUnitComment: this method will trigger the `removeComment` action with the commentId of the comment that should be deleted, along with the unitId and curren filters.
   * - resetCommentsDialog: this method will reset the `currentCommentsId` property to its initial value.
   */
  methods: {
    ...mapActions({
      getFilters: RENT_ACTIONS.GET_RENT_FILTER_DATA,
      getUnitsForRent: RENT_ACTIONS.GET_UNITS_FOR_RENT,
      addComment: RENT_ACTIONS.ADD_COMMENT,
      removeComment: RENT_ACTIONS.REMOVE_COMMENT,
      makeToast: TOAST_ACTIONS.MAKE_TOAST,
      updateStatus: RENT_ACTIONS.UPDATE_STATUS,
      updateRentalCode : RENT_ACTIONS.UPDATE_RENTAL_CODE,
      downloadRent: RENT_ACTIONS.DOWNLOAD,
    }),
    setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;

      if(filterDataParams.textSearch){
        this.resetSkip();
      }
      this.searchUnitsForRent();
    },
    rentStatus(updateStatus, unitId, rentableUnitId,statusData) {
      this.clickedStatus = true;

      if (updateStatus === 'ON_HOLD') {
        this.openCommentDialog(statusData,updateStatus)
      } else {
      const params = {
              skip : this.currentFilters.skip,
              limit:this.currentFilters.limit,
              ... this.filterParams
            };
      this.updateStatus({
        updateStatus,
        unitId,
        rentableUnitId,
        statusData,
        params
      });
      }
 // this.updateRentalCode({unitId:item._id,gapId:item.gapId || null,rentalCode:newCode,params: this.currentFilters});

		},
    formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
    editRentalCode (item) {
        if (!this.editing) {
          this.editing =item;

        } else {
          this.editing = null;
          const newCode = item.rentalCode.trim();
          if(newCode.length>0){
              const params = {
                skip : this.currentFilters.skip,
                limit:this.currentFilters.limit,
                ... this.filterParams
              };
              this.updateRentalCode({unitId:item._id,rentableUnitId:item.rentableUnitId,rentalCode:newCode,params});
          }
        }
      },
      cancelEdit(item){
        this.searchUnitsForRent();

        this.editing = null;
      },
    searchUnitsForRent: function () {
      // console.log(this.currentFilters)

      const params = {
          skip : this.currentFilters.skip,
          limit:this.currentFilters.limit,
          sortBy:this.currentFilters.sortBy,
          ... this.filterParams
        };
      this.getUnitsForRent(params);
    },
    resetSkip: function () {
      this.options.page = 1;
      this.currentFilters.skip = 0;
    },
    setSkip: function () {
      this.currentFilters.skip = this.options.page -1;
      this.searchUnitsForRent();
    },
    setLimit: function () {
      this.currentFilters.limit = this.options.itemsPerPage;
      this.searchUnitsForRent();
    },
    setSort: function () {
      console.log('SET SORT')
      const selectedSort = this.options.sortBy[0];
      const sortIsDesc = this.options.sortDesc[0];

      if (isNil(selectedSort)) {
        this.currentFilters.sortBy = null;

        this.searchUnitsForRent();
        return;
      }

      this.currentFilters.sortBy = sortIsDesc
        ? (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:1`)
        : (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:-1`);

      this.searchUnitsForRent();
    },
    openCommentDialog: function (unit,updatedStatus) {
      this.currentCommentsId = unit._id;
      this.updatedUnitStatus = updatedStatus;
    },
    addUnitComment: function (comment, status) {

      if (isNil(comment) || comment === "") {
        this.makeToast({ message: "Uw opmerking is leeg.", type: "error" });

        return;
      }
      const params = {
          skip : this.currentFilters.skip,
          limit:this.currentFilters.limit,
          ... this.filterParams
        };
      this.addComment({
        unitId: this.currentComments.unit,
        rentableUnitId :this.currentComments.rentableUnitId,
        comment,
        status: status,
        params,
      });
    },
    removeUnitComment: function (commentId) {
      const params = {
          skip : this.currentFilters.skip,
          limit:this.currentFilters.limit,
          ... this.filterParams
        };
      this.removeComment({
        unitId: this.currentComments.unit,
        commentId,
        params,
      });
    },
    resetCommentsDialog: function () {

      // reset status of unit.
        const unit = this.units.find(
        (unit) => unit._id === this.currentCommentsId
      );

      //
      this.currentCommentsId = undefined;
    },

    download: function() {
      const req = this.downloadRent({params: this.filterParams}).then(data => {
        let url = '';

        if (window.URL !== undefined && typeof window.URL === "function") {
          url = window.URL.createObjectURL(new Blob([data]));
        } else {
          const base64 = utf8_to_b64(data);
          url = `data:application/pdf;base64,${base64}`;
        }

        const element = document.createElement('a');

        element.href = url;
        element.setAttribute('download', 'opvolging-rent.csv');
        document.body.appendChild(element);

        element.click();
                element.remove();
      });

      return req;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
	&--container {
		width: 100%
	}

  &--select {
    width: 18.75rem
  }
}

.form-title {
  display: flex;
}

tr {
  background-color: #ffffff;
}

tr:nth-child(odd) {
  background-color: #f6f6f6;
}
</style>
