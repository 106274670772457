export const StayingListFilters = {
  START_DATE: {
    key: 'startDate',
    value: 'startDate',
  },
  END_DATE: {
    key: 'endDate',
    value: 'endDate',
  },
  PROJECT_NAME: {
    key: 'project.name',
    value: 'projectIds',
  },
  PROJECT_TYPE: {
    key: 'type',
    value: 'types',
  },
  UNIT_NAME: {
    key: 'unit.name',
    value: 'unitIds',
  },
  STAY_CHOICE_INFO: {
    key: 'stayChoiceInfo',
    value: 'stayChoicesInfo',
  },
  STAY_CHOICE: {
    key: 'stayChoice',
    value: 'stayChoices',
  },
  STAY_STATUS: {
    key: 'contractStatus',
    value: 'contractStatuses',
  },
}

export const FilterInitialValues = {
  [StayingListFilters.START_DATE.key]: null,
  [StayingListFilters.END_DATE.key]: null,
  [StayingListFilters.PROJECT_NAME.key]: [],
  [StayingListFilters.PROJECT_TYPE.key]: [],
  [StayingListFilters.UNIT_NAME.key]: [],
  [StayingListFilters.STAY_CHOICE_INFO.key]: [],
  [StayingListFilters.STAY_CHOICE.key]: [],
  [StayingListFilters.STAY_STATUS.key]: [],
};

export const FilterValues = [{
  filterValue: StayingListFilters.PROJECT_NAME.key,
  parameter: `&${StayingListFilters.PROJECT_NAME.value}=`
}, {
  filterValue: StayingListFilters.PROJECT_TYPE.key,
  parameter: `&${StayingListFilters.PROJECT_TYPE.value}=`
}, {
  filterValue: StayingListFilters.UNIT_NAME.key,
  parameter: `&${StayingListFilters.UNIT_NAME.value}=`
},
{
  filterValue: StayingListFilters.START_DATE.key,
  parameter: `&${StayingListFilters.START_DATE.value}=`
}, {
  filterValue: StayingListFilters.END_DATE.key,
  parameter: `&${StayingListFilters.END_DATE.value}=`
}, {
  filterValue: StayingListFilters.STAY_CHOICE_INFO.key,
  parameter: `&${StayingListFilters.STAY_CHOICE_INFO.value}=`
}, {
  filterValue: StayingListFilters.STAY_CHOICE.key,
  parameter: `&${StayingListFilters.STAY_CHOICE.value}=`
}, {
  filterValue: StayingListFilters.STAY_STATUS.key,
  parameter: `&${StayingListFilters.STAY_STATUS.value}=`
}];

export const StayerHeaders = [
  { text: 'Start', value: 'startDate', sortable: true },
  { text: 'Eind', value: 'endDate', sortable: true },
  { text: 'Project', value: 'project.name', sortable: false },
  { text: 'Kamer type', value: 'type', sortable: false },
  { text: 'Nummer', value: 'unitName', sortable: true },
  { text: 'Huur blijver', value: 'unitId.rent.stayer', sortable: false },
  { text: 'Huur vertrekker', value: 'unitId.rent.leaver', sortable: false },
  { text: 'Naam', value: 'tenant.fullName', sortable: false },
  { text: 'Keuze', value: 'stayChoice', sortable: false },
  { text: 'Info keuze', value: 'stayChoiceInfo', sortable: false },
  { text: 'Blijven/Vertrekken', value: 'contractStatus', sortable: true },
  { text: 'Opmerking', value: 'comments', sortable: false },
  { text: 'Url', sortable: false },
  // { text: 'Mail gegevens' }
]

export const StayerLabels = {
  'unitId.project': { title: 'Project' },
  'unitId.roomNumber': { title: 'Nummer kamer' },
  'unitId.roomType': { title: 'Type kamer' },
  'contractStatus': { title: 'Blijven/Vertrekken' },
}
