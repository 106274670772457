<template>
    <v-container grid-list-md text-xs-center class="container">
	    <v-flex md12 xs12 class="ma-5 mb-0">
		    <v-card class="card pa-4">
			    <div class="header">
				    <v-tabs
						    class="tabs"
						    v-model="active"
						    show-arrows
				    >
					    <v-tabs-slider></v-tabs-slider>
					    <v-tab v-if="!canSeeUsers" href="#gebruikers" active-class="active">Gebruikers</v-tab>
					    <v-tab v-if="!canSeeMollie" href="#eigenaars" active-class="active">Mollie setup</v-tab>
					    <v-tab v-if="!canSeeTemplates" href="#mailTemplates" active-class="active">Mail templates</v-tab>
					    <v-tab v-if="!canSeeRoles" href="#permissions" active-class="active">Rollen & Rechten</v-tab>
				    </v-tabs>
			    </div>
		    </v-card>
	    </v-flex>
        <div class="grid text-xs-center">
            <div id="profile-form">
                <v-tabs-items v-model="active">
                    <v-tab-item v-if="!canSeeUsers" :value="'gebruikers'">
                        <user-list></user-list>
                    </v-tab-item>
                    <v-tab-item v-if="!canSeeMollie" :value="'eigenaars'">
                        <company-list></company-list>
                    </v-tab-item>
                    <v-tab-item v-if="!canSeeTemplates" :value="'mailTemplates'">
                        <mail-templates-list></mail-templates-list>
                    </v-tab-item>
                    <v-tab-item v-if="!canSeeRoles" :value="'permissions'">
                        <permissions-list></permissions-list>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </v-container>
</template>

<script>
    import UserList from '../users/list/user-list';
	import CompanyList from "../companies/list/company-list";
    import MailTemplatesList from "../mail-templates/list/mail-templates-list";
    import PermissionsList from "../permissions/list/permissions-list";
    
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { GETTERS } from '@/account/account.constants';
    import { Roles } from '@/account/account.store';

    export default {
        name: 'Settings',
        components: {CompanyList, UserList, MailTemplatesList, PermissionsList},
        data() {
            return {
                active: null
            };
        },
        mounted() {
        },
        computed: {
            ...mapGetters({
                getRole: GETTERS.ROLE,
                getRights: GETTERS.RIGHTS
            }),
            canSeeRoles() {
                return this.getRights.ROLES_LIST.toLowerCase()  === 'none' 
            },
            canSeeUsers() {
                return this.getRights.SETTINGS_USERS.toLowerCase() === 'none' 
            },
            canSeeTemplates() {
                return this.getRights.SETTINGS_MAIL_TEMPLATES.toLowerCase() === 'none' 
            },
            canSeeMollie() {
                return this.getRights.SETTINGS_MOLLIE.toLowerCase() === 'none' 
            }
        }
    };
</script>

<style lang="scss">
    .grid {
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 10px 25px;
        border-radius: 4px;
    }

    #profile-form {
        margin-top: 10px;
    }

    .container {
        padding: 0;
        padding-top: 5rem !important;
        margin-left: 250px;
        margin-right: 250px;
        max-width: calc(100vw - 300px) !important;
    }

    .v-tabs-items {
        background-color: transparent !important;
    }

    .v-tabs__item {
        &.active {
            color: #150735;
            opacity: 1 !important;
        }
    }
</style>
