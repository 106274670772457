export const MODULE_NAME = 'profile';

export const ACTIONS = {
	UPDATE_PASS: `${MODULE_NAME}/UPDATE_PASS`,
	GET_USER: `${MODULE_NAME}/GET_USER`,
};


export const MUTATIONS = {
	SET_IS_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
};
