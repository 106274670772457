import api from '../../../services/backendApi';
import { isNil, isEmpty, update } from 'ramda';

export const NAME_FILTER_KEY = 'name';
export const STREET_FILTER_KEY = 'address';
export const ZIP_FILTER_KEY = 'address.zip';
export const CITY_FILTER_KEY = 'address.city';

const baseUrl = `${process.env.VUE_APP_API_URL}/projects`;
const config = { 
  headers: { 
    'Content-Type': 'application/json' 
  } 
};

const parseUnitsParams = (params) => {
  if (isNil(params) || isEmpty(params)) {
    return {
      skip: 0,
      limit: 10,
    };
  }

  // const name = params[NAME_FILTER_KEY];
  // const street = params[STREET_FILTER_KEY];
  // const zip = params[ZIP_FILTER_KEY];
  // const city = params[CITY_FILTER_KEY];

  return {
    ...params,
    skip: isNil(params.skip) ? 0 : params.skip,
    limit: isNil(params.limit) ? 10 : params.limit,

    // [NAME_FILTER_KEY]: !Array.isArray(name) ? null : name.join(','),
    // [STREET_FILTER_KEY]: !Array.isArray(street) ? null : street.join(','),
    // [ZIP_FILTER_KEY]: !Array.isArray(zip) ? null : zip.join(','),
    // [CITY_FILTER_KEY]: !Array.isArray(city) ? null : city.join(','),
  };
};

export default {
  getAllProjects: (params) => {
    return api.get(
      `${baseUrl}`,
      {
        ...config,
        params: parseUnitsParams(params),
      },
    );
  },
  createProject: (params, val) => {
    return api.post(
      `${baseUrl}`,
      val,
      config,
    );
  },
  editProject: (params, val) => {
    return api.patch(
      `${baseUrl}/${val._id}`,
      val,
      config,
    );
  },
  deleteProject: (params, val) => {
    return api.delete(
      `${baseUrl}/${val._id}`,
      config,
    );
  }
}