import api from '../../../services/backendApi';

const usersUrl = '/users';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getUser(userID) {
    return api.get(`${usersUrl}/${userID}`, config);
  },
  updatePass(data) {
		return api.patch(`${usersUrl}/me/update-password`, data, config);
  }
};
