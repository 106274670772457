import { rentService } from '../services/rent.service';
import { isEmpty, isNil } from 'ramda';

/**
 * This is the initial state of the rent store.
 * The rent store will contain:
 * - units: these are the units for rent. Fetched through the service
 * - totalCount: the total amount of units for rent. This number is provided with the above units from the API.
 * - filterData: the available filters and needed data. Fetched through the service.
 * - isLoading: this is an indicator that units are being fetched.
 * - error: when the request to fetch units fails, the error will be stored on this property.
 */
const rentInitialState = {
  units: [],
  totalCount: 0,
  filterData: null,
  isLoading: false,
  error: null,
};

/**
 * These are the available mutations for the rent store.
 * - SET_UNITS will store fetched units. It will also set the totalCount and set isLoading to false.
 * - SET_UNITS_FILTER_DATA will store the fetched filter-data.
 * - SET_LOADING will set the isLoading property to the provided value.
 * - SET_ERROR will store the provided error to the error property.
 */
export const RENT_MUTATIONS = {
  SET_UNITS: `rent/SET_UNITS`,
  SET_UNITS_FILTER_DATA: `rent/SET_UNITS_FILTER_DATA `,
  SET_LOADING: `rent/SET_LOADING`,
  SET_ERROR: `rent/SET_ERROR`,
};

export const rentMutations = {
  [RENT_MUTATIONS.SET_UNITS](state, { units, totalCount }) {
    state.units = isNil(units) || isEmpty(units)
      ? []
      : units;
    state.totalCount = totalCount;
    state.isLoading = false;
    state.error = null;
  },
  [RENT_MUTATIONS.SET_UNITS_FILTER_DATA](state, filterData) {
    state.filterData = isNil(filterData)
      ? null
      : filterData;
    state.error = null;
  },
  [RENT_MUTATIONS.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [RENT_MUTATIONS.SET_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
};

/**
 *  These are the available actions for the rent store.
 *  - GET_RENT_FILTER_DATA will get the filter-data through the service.
 *    If it resolves, it will store the filter-data in the store through the SET_UNITS_FILTER_DATA mutation.
 *    If it fails, it will store the error in the store through the SET_ERROR mutation.
 *  - GET_UNITS_FOR_RENT will trigger the SET_LOADING mutation to set isLoading to true, afterwards it will get the units through the service.
 *    When it resolves it will store the units in the store through the SET_UNITS mutation.
 *    If it fails, it will store the error in the store through the SET_ERROR mutation.
 *  - ADD_COMMENT will add a comment for a specific unit through the API and then re-fetch the current list of units
 *    through a similar flow like the GET_UNITS_FOR_RENT action.
 *  - REMOVE_COMMENT will remove a comment for a specific unit through the API and then re-fetch the current list of units
 *    through a similar flow like the GET_UNITS_FOR_RENT action.
 */
export const RENT_ACTIONS = {
  GET_RENT_FILTER_DATA: `rent/GET_FILTER_DATA`,
  GET_UNITS_FOR_RENT: `rent/GET_UNITS_FOR_RENT`,
  ADD_COMMENT: 'rent/ADD_COMMENT',
  REMOVE_COMMENT: 'rent/REMOVE_COMMENT',
  UPDATE_STATUS: 'rent/UPDATE_STATUS',
  UPDATE_RENTAL_CODE: 'rent/UPDATE_RENTAL_CODE',
  DOWNLOAD: 'rent/RENT_ACTIONS.DOWNLOAD'
};

const rentActions = {
  [RENT_ACTIONS.GET_UNITS_FOR_RENT]({ commit }, params) {
    commit(RENT_MUTATIONS.SET_LOADING, true);
    return rentService.getUnits(params)
      .then(({ data }) => {
        const { data: units, totalCount } = data;

        commit(RENT_MUTATIONS.SET_UNITS, { units, totalCount });
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },
  [RENT_ACTIONS.GET_RENT_FILTER_DATA]({ commit }) {
    return rentService.getFilterData()
      .then(({ data }) => {
        const { data: filterData } = data;

        commit(RENT_MUTATIONS.SET_UNITS_FILTER_DATA, filterData);
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },
  [RENT_ACTIONS.ADD_COMMENT]({ commit }, { unitId, rentableUnitId, comment, status, params }) {
    return rentService.addComment(unitId, comment, status, rentableUnitId)
      .then(() => {
        commit(RENT_MUTATIONS.SET_LOADING, true);

        return rentService.getUnits(params);
      })
      .then(({ data }) => {
        const { data: units, totalCount } = data;

        commit(RENT_MUTATIONS.SET_UNITS, { units, totalCount });
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },
  [RENT_ACTIONS.REMOVE_COMMENT]({ commit }, { unitId, commentId, params }) {
    return rentService.removeComment(unitId, commentId)
      .then(() => {
        commit(RENT_MUTATIONS.SET_LOADING, true);

        return rentService.getUnits(params);
      })
      .then(({ data }) => {
        const { data: units, totalCount } = data;

        commit(RENT_MUTATIONS.SET_UNITS, { units, totalCount });
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },
  [RENT_ACTIONS.UPDATE_STATUS]({ commit }, { updateStatus, unitId, rentableUnitId, statusData, params }) {
    return rentService.updateStatus(updateStatus, unitId, rentableUnitId, statusData)
      .then(() => {
        commit(RENT_MUTATIONS.SET_LOADING, true);

        return rentService.getUnits(params);
      })
      .then(({ data }) => {
        const { data: units, totalCount } = data;

        commit(RENT_MUTATIONS.SET_UNITS, { units, totalCount });
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },
  [RENT_ACTIONS.UPDATE_RENTAL_CODE]({ commit }, { unitId, rentalCode, rentableUnitId, params }) {
    return rentService.updateRentalCode(rentalCode, unitId, rentableUnitId)
      .then(() => {
        commit(RENT_MUTATIONS.SET_LOADING, true);

        return rentService.getUnits(params);
      })
      .then(({ data }) => {
        const { data: units, totalCount } = data;

        commit(RENT_MUTATIONS.SET_UNITS, { units, totalCount });
      })
      .catch((error) => {
        commit(RENT_MUTATIONS.SET_ERROR, error);
      });
  },

  [RENT_ACTIONS.DOWNLOAD]({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      rentService.downloadRent(params).then(
        result => resolve(result.data),
        error => reject(error)
      );
    });
  },
};

/**
 * This is an export of the store consisting of the above initial state, mutations and actions.
 */
export const store = {
  state: rentInitialState,
  mutations: rentMutations,
  actions: rentActions,
};
