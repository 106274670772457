import { RENT_LIST_HEADERS, UNIT_STATUSES } from '../list/rent-list.const';
import { isEmpty, propOr, pathOr } from 'ramda';

function formatDate(date) {
  //2000-01-01T00:00:00.000Z
  if (!date) return null
  const [year, month, day] = date.split('T')[0].split('-');
  return `${day}/${month}/${year}`
};

function formatFromTo(from, to) {

  const f = from ? formatDate(from) : '...';
  const t = to ? formatDate(to) : '...';

  return `${f} - ${t}`;

}

/**
 * The mapUnits helper will map the units from the API to a format that is readable by the data-table.
 *{{ item.comments.length !== 0 ? 'mdi - chat' : 'mdi-chat-outline' }}
 * The RENT_LIST_HEADERS and UNIT_STATUSES have been defined in ../list/rent-list.const.js to avoid typos and allow for easy tweaks in one place.
 *
 * @param units these are the provided units by the API. An array of units.
 * @returns data-table-items these are the parsed units in a format that is readable for the data-table.
 */
export default function (units) {
  if (isEmpty(units)) {
    return [];
  }

  return units.map((unit) => ({
    [RENT_LIST_HEADERS[0].value]: propOr('-', 'availableFrom', unit), // unit.availableFromDate => Available date
    [RENT_LIST_HEADERS[1].value]: propOr('-', 'projectName', unit), // unit.project.name
    [RENT_LIST_HEADERS[2].value]: propOr('-', 'unitType', unit), // unit.type
    [RENT_LIST_HEADERS[3].value]: propOr('-', 'unitName', unit), // unit.name => Room number
    [RENT_LIST_HEADERS[4].value]: propOr('-', 'projectCity', unit), // unit.project.name
    [RENT_LIST_HEADERS[5].value]: pathOr('-', ['latestPrices', 'rent'], unit), // unit.latestPrices.rent
    [RENT_LIST_HEADERS[6].value]: pathOr('-', ['latestPrices', 'egwi'], unit), // unit.latestPrices.egwi
    [RENT_LIST_HEADERS[7].value]: UNIT_STATUSES[propOr('DEFAULT', 'status', unit)], // unit.status
    [RENT_LIST_HEADERS[8].value]: propOr([], 'comments', unit), // unit.comments
    [RENT_LIST_HEADERS[9].value]: propOr('', 'rentalCode', unit), // unit.rentalCode
    _id: unit.unitId,
    rentableUnitId: unit._id,
    tableKey: unit._id,
    availableFromTo: formatFromTo(unit.availableFrom, unit.availableUntil)
  }));
}
