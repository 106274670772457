<template>
  <v-card>
    <v-card-title class="px-12 pt-8 pb-0">
      <h3 v-if="edit">Project aanpassen</h3>
      <h3 v-else>Project aanmaken</h3>
    </v-card-title>
    <v-card-text>
      <v-layout row wrap>
        <v-flex class="pa-8">
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  type="number"
                  hide-details
                  class="mx-auto m-0"
                  v-model="omniboxxId"
                  label="Omniboxx-id"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="9">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="name"
                  label="Naam"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="buildingNumber"
                  label="Nummer van gebouw / project"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" class="pb-0 mt-8">
                <h3>Adres</h3>
              </v-col>
              
              <v-col cols="12" sm="8">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="address.street"
                  label="Straatnaam"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="address.number"
                  label="Nummer"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="address.zip"
                  label="Zip"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  hide-details
                  class="mx-auto m-0"
                  v-model="address.city"
                  label="Stad"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-form>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions class="mb-2">
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('close')">
				Annuleren
			</v-btn>
      <v-btn color="blue darken-1" text @click="saveClicked" :disabled='!isComplete' v-if="canEdit">
        <span v-if="edit">Project aanpassen</span>
        <span v-else>Project aanmaken</span>
      </v-btn>

    </v-card-actions>
  </v-card>

</template>

<script>
  import api from '../../../../services/backendApi';
  import { isEmpty, propOr, pathOr } from 'ramda';

  import { GETTERS } from '@/account/account.constants';
  import { Roles } from '@/account/account.store';
	import { mapGetters } from 'vuex';

  export default {
    name: 'project-dialog',
    props: {
      item: {
				type: Object,
			},
			edit: {
				type: Boolean,
			},
      permission: {
        type: String,
      }
		},

    data: () => ({
      _id: null,
      omniboxxId: null,
      name: null,
      buildingNumber: null,
      address: {
        street: null,
        number: null,
        zip: null,
        city: null
      }
    }),

    created() {

		},

    mounted() {
      if (this.edit) {
        this.mapProject(this.item)
      }

    },

    computed: {
      ...mapGetters({
        getRole: GETTERS.ROLE
      }),
      canEdit() {
        return this.permission.toLowerCase() === 'edit'
      },
      isComplete () {
				return this.omniboxxId && this.name && this.buildingNumber && this.address && this.address.street && this.address.number && this.address.zip && this.address.city;
			}
    },

    watch: {

    },

    methods: {
      mapProject: function(data) {
        if (isEmpty(data)) {
          return [];
        }

        this._id = data._id
        this.omniboxxId = data.omniboxxId,
        this.name = data.name,
        this.buildingNumber = data.buildingNumber
        this.address = {
          street: data.address.street,
          number: data.address.number, 
          zip: data.address.zip,
          city: data.address.city
        }
      },

      saveClicked: function() {
        let data = {
          _id: this._id,
          omniboxxId: this.omniboxxId,
          name: this.name,
          buildingNumber: this.buildingNumber,
          address: {
            street: this.address.street,
            number: this.address.number,
            zip: this.address.zip,
            city: this.address.city
          }
        }

        this.$emit('save', {...data}, {toEdit: this.edit})
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>