<template>
	<v-container grid-list-md text-xs-center class="container">
		<v-layout row wrap>
			<v-flex md12 xs12 class="ma-5">
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title">
							Projects
						</h1>

						<div>
							<v-btn x-large color="blue-grey" class="white--text" @click="projectDialogItem = null; projectDialog = true; toEdit = false;">
								<v-icon small left dark>mdi-home-plus-outline</v-icon>
								Aanmaken
							</v-btn>
						</div>
					</div>

					<v-card flat :elevation="0">
						<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$projectList'"
								:showFilters="false"
								:showSearchBar="true"
                @filterData="setFilterOnList">
              </filter-data-dialog-component>

						</v-card-title>

						<v-data-table
								:headers="headers"
								:items="projects"
								:search="search"
								:options.sync="options"
								:server-items-length="totalCount"
								:loading="isLoading"
								:footer-props="{'items-per-page-options': [5, 10, 15]}"

                @update:page="setSkip"
                @update:items-per-page="setLimit"
                @update:sort-by="setSort"
                @update:sort-desc="setSort"
						>
							<template v-slot:header="{ props }">
								<tr class="grey lighten-3">
									<th
											v-if="props.headers"
											v-for="header in props.headers"
											:key="header.text"
									>
									</th>
								</tr>
							</template>

							<!-- The following template will provide an override for the edit cells -->
              <template v-slot:item.edit="{ item }">
								<td>
									<v-btn icon @click="projectDialogItem = {...item, ...item.edit}; projectDialog = true; toEdit = true;" v-if="!canSee">
										<v-icon medium dark>mdi-home-edit-outline</v-icon>
									</v-btn>
								</td>
              </template>

							<!-- The following template will provide an override for the delete cells -->

            	<!-- hide delete button (for now) it is not safe to remove projects , units are depending on them -->
              <!-- <template v-slot:item.delete="{ item }">
								<td class="test">
									<v-btn icon @click="confirmDeleteDialogItem = item.edit; confirmDeleteDialog = true">
										<v-icon medium dark>mdi-delete-outline</v-icon>
									</v-btn>
								</td>
              </template> -->

							<template slot="no-data">
								<v-alert :value="true" color="grey" icon="mdi-warn">
									Sorry, geen projecten gevonden
								</v-alert>
							</template>
						</v-data-table>
					</v-card>
				</v-card>
			</v-flex>
		</v-layout>


		<!-- Project component -->
		<v-dialog v-model="projectDialog" v-if="projectDialog" max-width="700px" scrollable persistent>
			<project-dialog :item="projectDialogItem" :edit="toEdit" :permission="getRights.PROJECTS_INFO" @save="saveProject" @close="projectDialog = false"></project-dialog>
		</v-dialog>

		<!-- Delete component -->
		<v-dialog v-model="confirmDeleteDialog" max-width="400px">
			<confirm-delete v-if="confirmDeleteDialog" :item="confirmDeleteDialogItem"
										@cancel="confirmDeleteDialogItem = null; confirmDeleteDialog = false"
										@delete="deleteClicked"></confirm-delete>
		</v-dialog>


	</v-container>
</template>

<script>
import { isNil, isEmpty, pathOr } from "ramda";

import { mapState, mapActions, mapGetters } from 'vuex'
import { ACTIONS } from '../projects.store';
import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';

import { mapProjects } from '../helpers'
import projectDialog from "../components/project-dialog";
import ConfirmDelete from '@components/dialog/confirm-delete';
import FilterDataDialogComponent from '../../../../components/data-filter/filter-data-dialog.component.vue';

// import ConfirmDelete from '@components/dialog/confirm-delete';
import api from '../../../../services/backendApi';
import { debounce } from "debounce";

import { GETTERS } from '@/account/account.constants';

import {
	FILTER_SORT_OPTIONS,
  INITIAL_FILTERS,
  PROJECTS_LIST_HEADERS
} from "./projects-list.const";

import { DATA_FILTERS,DATA_FILTERS_ITEMS } from '@/components/data-filter/const.js'

const PROJECT_ACCEPTED_FILTERS = [DATA_FILTERS.CITIES];
const unwind = require('javascript-unwind');

export default {
  components: { projectDialog, ConfirmDelete, FilterDataDialogComponent },
  data: () => ({
		acceptedFilters: PROJECT_ACCEPTED_FILTERS,
		filterParams: {},
		confirmDeleteDialogItem: null,
		confirmDeleteDialog: false,
		projectDialogItem: null,
		projectDialog: false,
		toEdit: null,
    search: "",
    options: {},
    filters: {},
    headers: PROJECTS_LIST_HEADERS,
    currentFilters: INITIAL_FILTERS,
		basicUrl: process.env.VUE_APP_API_URL,
  }),

  watch: {
    error: function () {
      if (!isNil(this.error)) {
        this.makeToast({
          message: "Er ging iets fout bij het ophalen van de data.",
          type: "error",
        });
      }
    },

  },

  mounted() {
    this.searchProjects()
  },

  computed: {
		...mapState({
      filterData: (state) =>
        parseFilterData(pathOr(null, ["projects", "filterData"], state)),
      projects: (state) => mapProjects(pathOr([], ["projects", "projects"], state)),
      isLoading: (state) => pathOr(false, ["projects", "isLoading"], state),
      totalCount: (state) => pathOr(0, ["projects", "totalCount"], state),
      error: (state) => pathOr(null, ["prorjects", "error"], state),
    }),
		...mapGetters({
			getRole: GETTERS.ROLE,
			getRights: GETTERS.RIGHTS
		}),
		canSee() {
      return this.getRights.PROJECTS_INFO.toLowerCase() === 'none'
    },
  },

  methods: {
    ...mapActions({
      getAllProjects: ACTIONS.GET_ALL_PROJECTS,
			createProject: ACTIONS.CREATE_PROJECT,
			editProject: ACTIONS.EDIT_PROJECT,
			deleteProject: ACTIONS.DELETE_PROJECT,
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
    }),
		setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;
			this.currentFilters = {...this.filterParams}
      this.getAllProjects(this.filterParams);
    },
		resetSkip: function () {
      this.options.page = 1;
			this.currentFilters.skip = 0;
    },
    setSkip: function () {
      this.currentFilters.skip = this.options.page -1;
      this.searchProjects();
    },
    setLimit: function () {
      this.currentFilters.limit = this.options.itemsPerPage;
      this.searchProjects();
    },
    setSort: function () {
      const selectedSort = this.options.sortBy[0];
      const sortIsDesc = this.options.sortDesc[0];

      if (isNil(selectedSort)) {
        this.currentFilters.sortBy = null;

        this.searchProjects();
        return;
      }

      this.currentFilters.sortBy = sortIsDesc
        ? (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:1`)
        : (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:-1`);

      this.searchProjects();
    },
    searchProjects: function () {
      this.getAllProjects(this.currentFilters);
    },
		saveProject(val, edit) {
			if (edit.toEdit) {
				this.editProject({
					params: this.currentFilters,
					val: val
				}).then(() => {
					this.projectDialog = false,

					this.makeToast({
						message: "Project werd aangemaakt / aangepast.",
						type: "success",
					})
				}, (error) => {
					this.makeToast({ message:'Er liep iets mis bij het bewerken van het project', type: 'error' });
				})
			} else {
				this.createProject({
					params: this.currentFilters,
					val: val
				}).then(() => {
						this.projectDialog = false,

						this.makeToast({
							message: "Project werd aangemaakt / aangepast.",
							type: "success",
						})
				}, (error) => {
					this.makeToast({ message:'Er liep iets mis bij het aanmaken van het project', type: 'error' });
				})
			}
		},
		deleteClicked(item) {
			this.deleteProject(
				{
					params: this.currentFilters,
					val: item
				}
			).then(() => {
				this.confirmDeleteDialog = false,
				this.confirmDeleteDialogItem = null,

				this.makeToast({
					message: "Project werd verwijderd.",
					type: "success",
				})
			}, (error) => {
				this.makeToast({ message:'Er liep iets mis bij het verwijderen van het project', type: 'error' });
			})
		}
  },
}
</script>

<style lang="scss" scoped>
	.search {
		max-width: 40rem;
		margin: 0 1rem 0 0;
	}

	.filter {
		&--container {
			width: 100%;
		}
	}
</style>
