<template>
    <v-container grid-list-md text-xs-center class="container">
      <v-flex md12 xs12 class="ma-5 mb-0">
        <v-card class="card pa-4">
          <div class="header">
            
            <h1 class="secondary-text text-lg-left form-title">
              Mijn profiel
              <v-spacer></v-spacer>
            </h1>
            <v-tabs
                class="tabs"
                v-model="active"
                show-arrows
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#personal" active-class="active">Persoonlijke informatie</v-tab>
              <v-tab href="#password" active-class="active">Wachtwoord</v-tab>
            </v-tabs>
          </div>
        </v-card>
      </v-flex>

      <div class="grid text-xs-center">
          <div id="profile-form">
              <v-tabs-items v-model="active">
                  <v-tab-item :value="'personal'">
                      <personal v-if="userLoaded" :user="user"></personal>
                  </v-tab-item>
                  <v-tab-item :value="'password'">
                      <password v-if="userLoaded" :user="user" @save="changePass"></password>
                  </v-tab-item>
              </v-tabs-items>
          </div>
      </div>
    </v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
	import { ACTIONS } from './profile.constants';
  import { ACTIONS as TOAST_ACTIONS } from './../../../components/toast/toast.constants';
  import Personal from '../profile/personal/personal';
  import Password from '../profile/password/password';

  export default {
      name: 'Profile',
      components: {Personal, Password},
      data() {
          return {
              active: null,
              user: {},
              userLoaded: false,
          };
      },
      mounted() {
        const usr = JSON.parse(localStorage.user)
        this.getUser(`${usr._id}`).then((res) => {
          this.user = {...res.data}
          this.userLoaded = true
        }, (error) => {
          this.makeToast({ message: error, type: 'error' })
        })
      },
      methods: {
        ...mapActions({
          makeToast: TOAST_ACTIONS.MAKE_TOAST,
          getUser: ACTIONS.GET_USER,
          updatePass: ACTIONS.UPDATE_PASS,
        }),
        changePass({currentPassword,newPassword}) {
          let password = {
            'current': `${btoa(currentPassword)}`,
            'new': `${btoa(newPassword)}`,
          }

          this.updatePass(password).then(() => {
            this.makeToast({
              message: `Wachtwoord werd succesvol aangepast`,
              type: 'success',
            });
          }, (error) => {
            this.makeToast({ message:'Er liep iets mis bij het aanpassen van het wachtwoord', type: 'error' });
          })
        }
      }
  };
</script>

<style lang="scss">
  .grid {
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 10px 25px;
    border-radius: 4px;
  }

  #profile-form {
    margin-top: 10px;
  }

  .container {
    padding: 0;
    padding-top: 5rem !important;
    margin-left: 250px;
    margin-right: 250px;
    max-width: calc(100vw - 300px) !important;
  }

</style>