<template>
  <div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-5">
					<div class="header d-flex align-center">


            <div class="avatarEdit mr-5 d-flex align-end" @click="dialog = true">
              <v-avatar color="grey lighten-4" size="62">
                <img
                  :src="basicUrl + '/images/' + user.avatar"
                  :alt="user.firstName"
                >
              </v-avatar>
              <v-icon class="ml-n5 mb-n1">
                mdi-pencil-circle
              </v-icon>
            </div>

            <div class="d-flex flex-column">
              <h3 class="secondary-text text-lg-left form-title">Hallo {{user.firstName}}</h3>
              <p class="body-1 ma-0">Hieronder een overzicht van jouw gegevens.</p>
            </div>
          </div>

          <v-divider class="mt-5"></v-divider>

          <div class="pl-10">
            <div class="mt-4 d-flex flex-row align-center py-4">
              <p class="profile-item body-1 grey--text text--lighten-1">Naam</p>
              <p class="profile-item body-1">{{user.lastName}}</p>
            </div>

            <div class="d-flex flex-row align-center pb-4">
              <p class="profile-item body-1 grey--text text--lighten-1">Voornaam</p>
              <p class="profile-item body-1">{{user.firstName}}</p>
            </div>

            <div class="d-flex flex-row align-center pb-4">
              <p class="profile-item body-1 grey--text text--lighten-1">E-mail</p>
              <p class="profile-item body-1">{{user.email}}</p>
            </div>

            <div class="d-flex flex-row align-center pb-4">
              <p class="profile-item body-1 grey--text text--lighten-1">Rol</p>
              <p class="profile-item body-1">{{user.role}}</p>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="700px">
			<picture-uploader v-if="dialog" :avatarSize="avatarSize" @uploadAvatar="onUploadAvatar($event); dialog=false"></picture-uploader>
		</v-dialog>
  </div>
</template>

<script>
  import api from '../../../../services/backendApi';
  import { mapActions } from 'vuex';
  import PictureUploader from '../../../../components/file-uploader/picture-uploader';
  import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';

  export default {
    name: 'personal',
    components: { PictureUploader },
    props: {
      user: {
        Type: Object,
      }
		},

    data() {
      return {
        basicUrl: process.env.VUE_APP_API_URL,
        dialog: false,
        imageBlob: null,
        userImage: null,
        valid: false,
        avatarSize: {
          width: 250,
          height: 250
        },
      }
    },

    created() {

		},

    mounted() {

},

    computed: {

    },

    watch: {

    },

    methods: {
      ...mapActions({
        makeToast: TOAST_ACTIONS.MAKE_TOAST,
      }),
      saveImage() {
        const formData = new FormData();
        let data = {
          avatar: this.imageBlob,
        }

        formData.append('body', JSON.stringify(data));

        if (this.imageBlob) {
          formData.append('avatar', this.imageBlob, 'avatar.jpg' );
        }

        api.post(`${this.basicUrl}/users/me/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' }}).then((res) => {
          this.makeToast({ message: 'Avatar werd succesvol opegladen', type: 'success' });
          this.$router.go()
        }, (error) => {
          this.makeToast({ message: error, type: 'error' });
        })
      },
      blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
            this.saveImage();
          };
        });
      },
      onUploadAvatar(event) {
        this.imageBlob = event;
        this.blobToBase64(event).then(res => {
          this.userImage = res;
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    margin-left: 250px;
    margin-right: 250px;
    max-width: calc(100vw - 300px);
  }

  .form-title {
    display: flex;
  }

  .profile {
    &-item {
      width: 50%;
    }
  }
  
  .avatarEdit {
    &:hover {
      cursor: pointer ;
    }

    .mdi-pencil-circle {
      color: #1565C0;
      opacity: 100%;
      background: white;
      border-radius: 50%;
    }
  }
</style>