<template>
	<v-container grid-list-md text-xs-center class="" v-if="tenant">
		<div class="grid text-xs-center">
			<v-card class="profile">
				<v-layout row wrap>
					<v-flex md6 xs12>
						<div class="profile_main">
							<div class="profile_main-info">
							<h2 class="grey--text text-lg-left text--darken-3 mt-3">{{tenant.firstName}} {{tenant.lastName}}</h2>
							<h4 class="grey--text text-lg-left">{{tenant.email}}</h4>
							</div>
						</div>
					</v-flex>
					<v-divider class="mx-3" inset vertical></v-divider>
					<v-flex md4 xs12>
						<table class="tenant-info">
							<tr ><td class="lbl">tel</td><td class="nfo">{{tenant.telephone}}</td></tr>
							<tr ><td class="lbl">nood tel</td><td class="nfo">{{tenant.emergencyNumber}}</td></tr>
							<tr ><td class="lbl">taal</td><td class="nfo">{{tenant.language}}</td></tr>
							<tr ><td class="lbl">cNummer</td><td class="nfo">{{tenant.cNumber}}</td></tr>
						</table>
						
					</v-flex>
				</v-layout>
			</v-card>
		</div>
		<div class="grid text-xs-center">
			<v-layout row wrap>
				<v-flex md4 xs12>
					<v-card class="tenant-card contract-list" >
						<div @click="getPaymentInfo(contract)" v-if="contracts" class="text-xs-left clickable-item" v-for="contract in contracts" >
							<div>{{contract.startDate | formatDate}} - {{contract.endDate | formatDate}}</div>
							<div>{{contract.unit.project.name}}, {{contract.unit.name}}</div>
						</div>
						<div v-else class="text-xs-left">geen contracten gevonden</div>
					</v-card>
				</v-flex>
				<v-flex md8 xs12>
					<v-card class="tenant-card contract-payment-info" v-if="selectedContract">
						<h6>subscriptions</h6>
						<div v-for="subscription in selectedContract.subscriptions">
							{{subscription.paymentType}}
						</div>
					</v-card>
				</v-flex>
			</v-layout>
		</div>
	</v-container>

	<v-container grid-list-md text-xs-center class="" v-else>
		<div class="grid text-xs-center">
			<v-card class="profile">
				Geen data gevonden
			</v-card>
		</div>
	</v-container>
</template>

<script>
import { ACTIONS as TOAST_ACTIONS } from "../../../../components/toast/toast.constants.js";
import {ACTIONS} from "../tenant.const";
import { mapActions, mapState } from 'vuex';
import { isNil, pathOr } from "ramda";

export default {
	name: 'TenantDetail',
	components:{},
	data: ()=>({
	}),
	mounted: function (){
		const tenantId = this.$route.params.id;
		console.log(tenantId);
		this.getTenantDetail(tenantId);
		this.getTenantContracts(tenantId);
	},
	methods :{
		...mapActions({
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
			getTenantDetail: ACTIONS.GET_DETAIL_TENANT,
			getTenantContracts: ACTIONS.GET_TENANT_CONTRACTS,
			getTenantSelectedContract: ACTIONS.GET_TENANT_SELECTED_CONTRACT
		}),
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		getPaymentInfo(contract){
			const contractId = contract._id;
			const tenantId = this.tenant._id;
			console.log(contractId,tenantId);
			this.getTenantSelectedContract({tenantId,contractId});
		}
	},
	computed : {
		...mapState({
			tenant:(state)=> pathOr(null,["tenants","currentTenant"],state),
			contracts:(state)=> pathOr(null,["tenants","currentTenantContracts"],state),
			selectedContract:(state)=> pathOr(null,["tenants","currentTenantSelectedContract"],state),
			isLoading: (state) => pathOr(false, ["tenants", "isLoading"], state),
			error: (state) => pathOr(null, ["tenants", "error"], state),
		})
	},
	watch: {
    error: function () {
      if (!isNil(this.error)) {
        this.makeToast({
          message: "Er ging iets fout bij het ophalen van de data.",
          type: "error",
        });
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.grid {
		display: flex;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		flex-direction: column;
		margin-bottom: 20px;
		padding: 10px 25px;
		border-radius: 4px;
	}

	#profile-form {
		margin-top: 10px;
	}

	.container {
		padding: 0;
		padding-top: 5rem !important;
		margin-left: 250px;
		margin-right: 250px;
		max-width: calc(100vw - 300px)!important;
	}

	.v-tabs-items {
		background-color: transparent!important;
	}

	.v-tabs__item {
		&.active {
			color: #150735;
			opacity: 1 !important;
		}
	}

	
	.profile {
    padding: 10px !important;
    border-radius: 7px !important;
  }

  .profile_main {
    display: flex;
    flex-direction: row;

    .profile_main-info {
      margin: 1.5rem 0;
    }
  }
  .tenant-info{
	font-size: 0.8em;
	.lbl{
			font-weight: bold;
			text-align: right;
		}
		.nfo{
			padding-left: 10px;
		}
  }

  .tenant-card{
	margin-right: 5px;
	padding: 10px 0 !important;
	border-radius: 7px !important;

	&.contract-list{
		font-size: 0.8em;

		.clickable-item{
			cursor: pointer;
			padding: 0 10px;
			border-radius: unset;
			width: 100%;
			border-bottom: 1px solid #d4d3d3;

			&:hover{
				background-color: #eeeeee; 
			}
		}
	}
	&.contract-payment-info{
		padding: 0 10px !important;
			border-radius: unset;
			width: 100%;
	}
  }


</style>