import { NAME_FILTER_KEY, STREET_FILTER_KEY, ZIP_FILTER_KEY, CITY_FILTER_KEY } from '../projects.service';

/**
 * The PROJECTS_LIST_HEADERS constant contains all headers for the table.
 * These headers are used by the `headers` property in the rent-list component.
 *
 * This is also the place to set if a header should be sortable.
 * To enable this functionality, don't forget to add it in the `setSort` method of the rent-list component.
 *
 * align: ' d-none' -> prevents column from showing in vuetify table
 */
export const PROJECTS_LIST_HEADERS = [
  {
    text: '',
    value: 'edit',
    sortable: true,
    width: '1rem',
  },
  {
    text: 'Naam',
    value: 'name',
    sortable: true,
  },
  {
    text: 'Adres',
    value: 'street',
    sortable: true,
  },
  {
    text: 'Zip',
    value: 'zip',
    sortable: true,
  },
  {
    text: 'Stad',
    value: 'city',
    sortable: true,
  },
  {
    text: ' ',
    value: 'delete',
    sortable: true,
    width: '1rem',
  },
];

/**
 * The INITIAL_FILTERS constant is defined to have an initial set of filters for the data-table.
 * These filters are used by the `currentFilters` property in the rent-list component.
 *
 * Note: these filters are used on the initial request to fetch the first x-amount of units.
 */
export const INITIAL_FILTERS = {
  skip: 0,
  limit: 10,
  sortBy: null,
}

/**
 * The FILTER_SORT_OPTIONS constant is used to map header values to filter and sort values for the API.
 *
 * For example `project` will be mapped to `project.name` for sorting or filtering the project column.
 */

export const FILTER_SORT_OPTIONS = {
  [PROJECTS_LIST_HEADERS[1].value]: NAME_FILTER_KEY,
  [PROJECTS_LIST_HEADERS[2].value]: STREET_FILTER_KEY,
  [PROJECTS_LIST_HEADERS[3].value]: ZIP_FILTER_KEY,
  [PROJECTS_LIST_HEADERS[4].value]: CITY_FILTER_KEY,
}