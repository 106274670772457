import { isNil, isEmpty, update } from 'ramda';

import api from '@/services/backendApi';

/**
 * These filter keys are defined in constants so that they can be set in one place.
 * This eliminates the danger of typos and avoids complex search & replaces.
 *
 * These are used in the parseUnitsParams but also in ../list/rent-list.const.js
 */
export const PROJECT_NAME_FILTER_KEY = 'projectIds';
export const TYPE_FILTER_KEY = 'types';
export const STATUS_FILTER_KEY = 'statuses';
export const CITY_FILTER_KEY = 'cities';

export const BETWEENRANGE_FILTER_KEY = 'betweenRange';
export const UNIT_NAME_FILTER_KEY = 'unitName';

/**
 * The baseUrl and config are the two shared parts of each request in the service.
 * That's why they are defined as constants, to avoid duplicate code.
 */
const baseUrl = `${process.env.VUE_APP_API_URL}/units`;
const config = {
  headers: {
    'Content-Type': 'application/json'
  },
};

/**
 * The following helper will parse the params for a request to fetch the units.
 * It will set a skip and limit default if they are not provided.
 * It will check if filters and join them into a string.
 *
 * @param params this is the set of provided params
 * @returns params these will comply with the params that can be provided through the axios request config: https://axios-http.com/docs/req_config
 */
const parseUnitsParams = (params) => {
  if (isNil(params) || isEmpty(params)) {
    return {
      skip: 0,
      limit: 10,
    };
  }
  const projectName = params[PROJECT_NAME_FILTER_KEY];
  const name = params[TYPE_FILTER_KEY];
  const projectCity = params[CITY_FILTER_KEY];
  const status = params[STATUS_FILTER_KEY];
  const betweenRange = params[BETWEENRANGE_FILTER_KEY];

  return {
    ...params,
    skip: isNil(params.skip) ? 0 : params.skip,
    limit: isNil(params.limit) ? 10 : params.limit,
    [PROJECT_NAME_FILTER_KEY]: !Array.isArray(projectName) ? null : projectName.join(','),
    [TYPE_FILTER_KEY]: !Array.isArray(name) ? null : name.join(','),
    [CITY_FILTER_KEY]: !Array.isArray(projectCity) ? null : projectCity.join(','),
    [STATUS_FILTER_KEY]: !Array.isArray(status) ? null : status.join(','),
    [BETWEENRANGE_FILTER_KEY]: !Array.isArray(betweenRange) ? null : JSON.stringify(betweenRange),
  };
};

/**
 * The rentService performs all requests for the rent module.
 * It is build up out of two functions:
 * - getFilterData will access `/units/for-rent/filter-data` to get the filter-data from the API.
 * - getUnits will access `/units/for-rent` with a provided set of query-params to get the units from the API.
 * - addComment will access `<insert endpoint>` with a new comment for a specific unit.
 * - removeComment will access `<insert endpoint>` with a the request to remove a comment for a specific unit.
 */
export const rentService = {
  getFilterData: () => {
    return api.get(
      `${baseUrl}/for-rent/filter-data`,
      config,
    );
  },
  getUnits: (params) => {
    return api.get(
      `${baseUrl}/for-rent`,
      { config, params },
    );
  },
  addComment: (unitId, comment, status, rentableUnitId = null) => {
    let commentData = {}
    status.clickedStatus ? commentData = { text: comment, status: status.statusValue, rentableUnitId } : commentData = { text: comment }

    return api.post(
      `${baseUrl}/${unitId}/comment`,
      commentData,
      { text: comment },
      config,
    );
  },
  removeComment: (unitId, commentId) => {
    return api.delete(
      `${baseUrl}/${unitId}/comment/${commentId}`,
      config,
    );
  },
  updateStatus: (updateStatus, unitId, rentableUnitId, statusData) => {
    return api.patch(
      `${baseUrl}/${unitId}`,
      { 'status': updateStatus, rentableUnitId },
      config,
    );
  },
  updateRentalCode: (rentalCode, unitId, rentableUnitId) => {
    return api.patch(
      `${baseUrl}/${unitId}`,
      { rentalCode, rentableUnitId },
      config,
    );
  },


  downloadRent: (params) => {
    return api.get(`/downloads/units/for-rent`, {
      config, params
    })
  },
};
