<template>
  <v-card>
		<v-card-title>
			<h3>Beïndigen van contract</h3>
		</v-card-title>
		<v-card-text>
      <v-layout row wrap>
				<v-flex class="pa-3">
					<v-form ref="form">
						<v-row>
              <v-col cols="12" sm="12">
                <h3 class="black--text">Gelieve een datum te selecteren waneer de unit terug beschikbaar is</h3>
                <h4>{{item.project.name}} - {{item.unit.name}} ({{item.unit.type}})</h4>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateFormatted"
                      label="Beschikbaar vanaf"
                      persistent-hint
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="endDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                      v-model="unitRentalCode"
                      label="Verhuur code"
                      persistent-hint
                      clearable
                      @click:clear="unitRentalCode = ''"
                    ></v-text-field>
              </v-col>
              <v-col class="d-flex justify-end mr-2">
                <v-btn color="blue darken-1" text @click="cancel">Annuleren</v-btn>
                <v-btn color="blue darken-1" text @click="saveClicked">Contract Beëindigen</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-flex>
      </v-layout>

    </v-card-text>
  </v-card>
</template>

<script>
  import moment from 'moment';
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'end-contract',
    props: {
			item: {
				type: Object,
			},
		},
    data: vm => ({
      endDate: null,
      unitRentalCode:'',
      menu1: false,
    }),
    mounted() {
      this.item.endDate != null ? this.endDate = moment(String(this.item.endDate )).format('YYYY-MM-DD') : this.endDate = format(parseISO(new Date().toISOString()), 'YYYY-MM-DD') ;
      this.unitRentalCode = this.item.unitRentalCode || '';
    },
    computed: {
      endDateFormatted () {
        return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
      }
    },
    watch: {

    },
    methods: {
      saveClicked() {
        this.$emit('save', {
          status: this.item.contractStatuses,
          releaseUnitDate: this.endDate,
          unitRentalCode: this.unitRentalCode,
          contractId: this.item._id
        });
      },
      cancel() {
				this.$emit('close', {
					item: this.item,
				});
				Object.assign(this.item, this.initialData);
			},
    },


}
</script>

<style>

</style>
