<template>
		<div>
			<sidebar></sidebar>
			<toolbar></toolbar>
			<router-view></router-view>
		</div>
</template>

<script>
	import Sidebar from '../../../components/main-layout/sidebar';
	import Toolbar from '../../../components/main-layout/toolbar';

	export default {
		name: 'Root',
		components: { Toolbar, Sidebar },
	};
</script>


<style lang="scss">
	#router-view {
		display: flex;
		background-color: #00060d;

		.page-wrapper {
			display: flex;
			flex-flow: column;
			width: 100%;
			overflow: auto;

			.page-header {
				display: inline-flex;
				flex: 0 0 auto;
			}

			.container {
				flex: 1 1 100%;
				overflow-y: auto;
			}
		}
	}
</style>
