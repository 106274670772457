<template>
	<v-card>
		<v-card-title>
			<h3>Opmerkingen</h3>
		</v-card-title>
		<v-card-text class="pb-3">
			<v-layout row wrap>
				<v-flex md12 class="pa-5 pb-0">
					<div class="radio-tile-group flex wrap">
						<v-card v-for="(remark, key) in item.item.comments" elevation="2" class="comments pa-4 mb-2" :key="key">
							<div class="d-flex justify-space-between">
								<div>
									<p class="text-caption mb-1 font-weight-light">{{ remark.createdBy }}</p>
									<p class="text-caption mb-1 font-weight-light">{{ remark.createdDateTime | formatDate }}</p>
								</div>
								<div v-if="canEdit">
									<v-icon class="mt-0" @click="deleteClicked(remark)" v-if="remark._id">mdi-delete-forever</v-icon>
								</div>
							</div>


							{{ remark.text }}
						</v-card>

						<v-card v-for="(liveRemark, key) in liveComment" elevation="2" class="comments pa-4 mb-2" :key="'A'+ key">
							<div class="d-flex justify-space-between">
								<div>
									<p class="text-caption mb-1 font-weight-light">{{ liveRemark.createdBy }}</p>
									<p class="text-caption mb-1 font-weight-light">{{ liveRemark.createdDate }}</p>
								</div>
								<div>
									<v-icon class="mt-0" @click="deleteClicked(remark)" v-if="remark._id">mdi-delete-forever</v-icon>
								</div>
							</div>

							{{ liveRemark.text }}
						</v-card>

						<v-textarea class="mt-3" placeholder="Schrijf hier uw opmerkingen" v-model="comment" hide-details></v-textarea>
					</div>
				</v-flex>
			</v-layout>
		</v-card-text>
		<v-card-actions class="mr-2 pb-4">
			<v-spacer></v-spacer>
			<v-btn v-if="canEdit" color="primary" @click="saveClicked">Verzenden</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex';
	import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants.js';

	export default {
		name: "stay-dialog",
		props: { 
			item: {
				type: Object,
			},
			canEdit: {
				type: Boolean,
			}
		},
		data: () => ({
			liveComment: [],
			status: true,
			comment: "",
			commentStatus: false,
			commentDate: "",
			commentCurrentUser: "",
		}),
		mounted() {
			this.sortedComments;
		},
		computed:{
      sortedComments(){
        const sortedComm = this.item.item.comments.sort((a, b) => (a.createdDateTime < b.createdDateTime) ? 1 : ((a.createdDateTime > b.createdDateTime) ? -1 : 0));
        return 	sortedComm;
      }
    },
		methods: {
			...mapActions({
        makeToast: TOAST_ACTIONS.MAKE_TOAST,
			}),
						
			saveClicked() {
				let username = JSON.parse(localStorage.getItem('user'));
				this.commentCurrentUser = username.firstName + ' ' + username.lastName;

				if (this.comment === "") {
					this.makeToast({ message: "Uw opmerking is leeg", type: 'error' });
				} else {
					function addZero(i) {
						if (i < 10) {
							i = "0" + i;
						}
						return i;
					}

					let d = new Date();
					let month = '' + (d.getMonth() + 1);
					let day = '' + d.getDate();
					let year = d.getFullYear();
					let cDate = [year, month, day].join('/');
					this.commentDate = [cDate];

					let commentObj = {createdBy: this.commentCurrentUser, createdDateTime: this.commentDate, text: this.comment};
					this.item.item.comments.push(commentObj)
					// this.liveComment.push(commentObj)

					this.sortedComments;

					this.$emit('save', {
						_id: this.item._id,
						comment: this.comment,
						commentStatus: true,
						commentDate: this.commentDate,
						commentCurrentUser: this.commentCurrentUser
					});
					this.comment = "";
				};
			},
			deleteClicked(comment) {
				this.item.item.comments = this.item.item.comments.filter((remark) => remark._id !== comment._id);

				this.$emit('delete', {
					contractId: this.item._id,
					remarkId: comment._id,
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	$primary-color: #999999;

	.radio-tile-group {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		.comments {
			width: 100%;
		}

		button {
			width: 40%;
			margin-top: 20px;
			margin-right: 5%;
		}
	}
</style>
