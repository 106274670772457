<template>
    <v-container grid-list-md text-xs-center class="container">
	    <v-flex md12 xs12 class="ma-5 mb-0">
		    <v-card class="card pa-4">
			    <div class="header">
				    <h1 class="secondary-text text-lg-left form-title">
					    Support
					    <v-spacer></v-spacer>
				    </h1>
				    <v-tabs
						    class="tabs"
						    v-model="active"
						    show-arrows
				    >
					    <v-tabs-slider></v-tabs-slider>
					    <v-tab href="#intredes" active-class="active" v-if="!canSeeEnties">Opvolging intredes</v-tab>
					    <v-tab href="#blijvers" active-class="active" v-if="!canSeeStayers">Opvolging blijvers</v-tab>
                        <v-tab href="#huurluik" active-class="active" @click="!updateRentList" v-if="!canSeeRent">Opvolging rent</v-tab>
				    </v-tabs>
			    </div>
		    </v-card>
	    </v-flex>
        <div class="grid text-xs-center">
            <div id="profile-form">
                <v-tabs-items v-model="active">
                    <v-tab-item v-if="!canSeeEnties" :value="'intredes'">
                        <entries-list :extended=true></entries-list>
                    </v-tab-item>
                    <v-tab-item v-if="!canSeeStayers" :value="'blijvers'">
                        <staying-list :extended=false></staying-list>
                    </v-tab-item>
                    <v-tab-item v-if="!canSeeRent" :value="'huurluik'">
                        <rent-list></rent-list>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </v-container>
</template>

<script>
    import StayingList from '../staying/list/staying-list';
    import EntriesList from '../entries/list/entries-list';
    import RentList from '../rent/list/rent-list';

    import { mapGetters } from 'vuex'
    import { GETTERS } from '@/account/account.constants';

    export default {
        name: 'Support',
        components: {StayingList, EntriesList, RentList},
        data() {
            return {
                active: null,
            };
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
                getRights: GETTERS.RIGHTS
            }),
            canSeeEnties() {
                return this.getRights.FOLLOW_UP_ENTIES.toLowerCase() === "none"
            },
            canSeeStayers() {
                return this.getRights.FOLLOW_UP_STAYERS.toLowerCase() === "none"
            },
            canSeeRent() {
                return this.getRights.FOLLOW_UP_RENT.toLowerCase() === "none"
            },
        },
        methods: {
            updateRentList() {
                this.$root.$emit('rent-list-units')
            }
        }
    };
</script>

<style lang="scss">
    .grid {
        display: flex;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 10px 25px;
        border-radius: 4px;
    }

    #profile-form {
        margin-top: 10px;
    }

    .container {
        padding: 0;
        padding-top: 5rem;
        margin-left: 250px;
        margin-right: 250px;
        max-width: calc(100vw - 300px) !important;
    }

    .v-tabs-items {
        background-color: transparent !important;
    }

    .v-tabs__item {
        &.active {
            color: #150735;
            opacity: 1 !important;
        }
    }
</style>
