import { ACTIONS, MUTATIONS } from './profile.constants';
import profileService from './profile.service';

const payment = {
	state: {
		loading: false,
	},
	actions: {
		[ACTIONS.UPDATE_PASS]({ commit }, data) {
			return new Promise((resolve, reject) => {
				profileService.updatePass(data)
					.then((result) => {
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
		[ACTIONS.GET_USER]({ commit }, userID) {
			return new Promise((resolve, reject) => {
				profileService.getUser(userID)
					.then((result) => {
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
	},
	mutations: {
		[MUTATIONS.SET_IS_LOADING](state, loadingState) {
			state.loading = loadingState;
		}
	},
};


export default payment;