import { PROJECT_NAME_FILTER_KEY, TYPE_FILTER_KEY, STATUS_FILTER_KEY, BETWEENRANGE_FILTER_KEY, CITY_FILTER_KEY, UNIT_NAME_FILTER_KEY } from '../services/rent.service';

/**
 * The RENT_LIST_HEADERS constant contains all headers for the table.
 * These headers are used by the `headers` property in the rent-list component.
 *
 * This is also the place to set if a header should be sortable.
 * To enable this functionality, don't forget to add it in the `setSort` method of the rent-list component.
 *
 * align: ' d-none' -> prevents column from showing in vuetify table
 */
export const RENT_LIST_HEADERS = [
  {
    text: 'Datum beschikbaarheid',
    value: 'betweenRange',
    sortable: true,
  },
  {
    text: 'Project',
    value: 'projectName',
    sortable: false,
  },
  {
    text: 'Kamer Type',
    value: 'unitType',
    sortable: false,
  },
  {
    text: 'Nummer',
    value: 'unitName',
    sortable: true,
  },
  {
    text: 'Stad',
    value: 'projectCity',
    sortable: false,
  },
  {
    text: 'Huur',
    value: 'rent',
    sortable: false,
  },
  {
    text: 'EGWI',
    value: 'egwi',
    sortable: false,
  },
  {
    text: 'Status',
    value: 'status',
    sortable: false,
  },
  {
    text: 'Opmerkingen',
    value: 'unitRemarks',
    sortable: false,
  },
  {
    text: 'Unieke Code',
    value: 'rentalCode',
    sortable: false,
  }
];

/**
 * The INITIAL_FILTERS constant is defined to have an initial set of filters for the data-table.
 * These filters are used by the `currentFilters` property in the rent-list component.
 *
 * Note: these filters are used on the initial request to fetch the first x-amount of units.
 */
export const INITIAL_FILTERS = {
  skip: 0,
  limit: 10,
  textSearch: null,
  sortBy: null,
}

/**
 * The INITIAL_COMMENTS is a constant that defines the intial state of the currentComments property.
 */

export const INITIAL_COMMENTS = {
  show: false,
  comments: [],
  unit: null,
};

/**
 * The FILTER_SORT_OPTIONS constant is used to map header values to filter and sort values for the API.
 *
 * For example `project` will be mapped to `project.name` for sorting or filtering the project column.
 */
export const FILTER_SORT_OPTIONS = {
  [RENT_LIST_HEADERS[0].value]: BETWEENRANGE_FILTER_KEY,
  [RENT_LIST_HEADERS[1].value]: PROJECT_NAME_FILTER_KEY,
  [RENT_LIST_HEADERS[2].value]: TYPE_FILTER_KEY,
  [RENT_LIST_HEADERS[3].value]: UNIT_NAME_FILTER_KEY,
  [RENT_LIST_HEADERS[4].value]: CITY_FILTER_KEY,
  [RENT_LIST_HEADERS[7].value]: STATUS_FILTER_KEY,
}

/**
 * The UNIT_STATUSES constant is an object containing all statuses for the units.
 * They allow easy mapping of the status in a unit object of the API to human readable values in the table (see ../helpers/map-units.js).
 */
export const UNIT_STATUSES = {
  FOR_RENT: 'FOR_RENT',
  ON_HOLD: 'ON_HOLD',
  CODE_SENT: 'CODE_SENT',
  OWNER: 'OWNER',
  RESERVED: 'RESERVED',
  CODE_SENT: 'CODE_SENT',
  SALES: 'SALES'
};

/**
 * Only FOR_RENT and RESERVED are adjustable statuses
 */

export const ADJUSTABLE_UNIT_STATUSES = [
  {
    'name': 'Te huur',
    '_id': 'FOR_RENT'
  },
  {
    'name': 'Code verzonden',
    '_id': 'CODE_SENT'
  },
  {
    'name': 'On hold',
    '_id': 'ON_HOLD'
  },
  {
    'name': "Sales",
    '_id': "SALES"
  }
];
