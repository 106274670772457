export const MODULE_NAME = 'tenants';

export const ACTIONS = {
    GET_ALL_TENANTS: `${MODULE_NAME}/GET_ALL_TENANTS`,
    GET_DETAIL_TENANT: `${MODULE_NAME}/GET_DETAIL_TENANT`,
    GET_TENANT_CONTRACTS: `${MODULE_NAME}/GET_TENANT_CONTRACTS`,
    GET_TENANT_SELECTED_CONTRACT: `${MODULE_NAME}/GET_TENANT_SELECTED_CONTRACT`,
};

export const MUTATIONS = {
    SET_ALL_TENANTS: `${MODULE_NAME}/SET_ALL_TENANTS`,
    SET_DETAIL_TENANT: `${MODULE_NAME}/SET_DETAIL_TENANT`,
    SET_TENANT_CONTRACTS: `${MODULE_NAME}/SET_TENANT_CONTRACTS`,
    SET_TENANT_SELECTED_CONTRACT: `${MODULE_NAME}/SET_TENANT_SELECTED_CONTRACT`,
    SET_LOADING: `${MODULE_NAME}/SET_IS_LOADING`,
    SET_ERROR: `${MODULE_NAME}/SET_ERROR`,
  };

export const TENANT_LIST_HEADERS = [
    {
        text: 'voornaam',
        value: 'firstName',
        sortable: true,
    },
    {
        text: 'naam',
        value: 'lastName',
        sortable: true,
    },
    {
        text: 'e-mail',
        value: 'email',
        sortable: true,
    },{
        text: 'telefoon',
        value: 'telephone',
        sortable: true,
    },
];

export const INITIAL_FILTERS = {
    skip: 0,
    limit: 10,
    textSearch: null,
    sortBy: null,
  };

export const FILTER_SORT_OPTIONS = {
    [TENANT_LIST_HEADERS[0].value]: 'firstName',
    [TENANT_LIST_HEADERS[1].value]: 'lastName',
    [TENANT_LIST_HEADERS[2].value]: 'email',
    [TENANT_LIST_HEADERS[3].value]: 'telephone'
  }