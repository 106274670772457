// import { ACTIONS, MUTATIONS } from './projects.constants';
import projectsService from './projects.service';
import { isEmpty, isNil } from 'ramda';

const projectsState = {
  projects: [],
  totalCount: 0,
  filterData: null,
  isLoading: false,
  error: null,
};

/**
 * ----------------------------------------------------------------------------------------------------------------
 */

export const PROJECTS_MUTATIONS = {
	SET_ALL_PROJECTS: `projects/SET_ALL_PROJECTS`,
	SET_DETAIL_PROJECT: `projects/SET_DETAIL_PROJECT`,
	SET_LOADING: `projects/SET_LOADING`,
};

export const projectsMutations = {
  [PROJECTS_MUTATIONS.SET_ALL_PROJECTS](state, { projects, totalCount }) {
    state.projects = isNil(projects) || isEmpty(projects)
      ? []
      : projects;
    state.totalCount = totalCount;
    state.isLoading = false;
    state.error = null;
  },
  // [MUTATIONS.SET_UNITS_FILTER_DATA](state, filterData) {
  //   state.filterData = isNil(filterData)
  //     ? null
  //     : filterData;
  //   state.error = null;
  // },
  [PROJECTS_MUTATIONS.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [PROJECTS_MUTATIONS.SET_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
};

/**
 * ----------------------------------------------------------------------------------------------------------------
 */

export const ACTIONS = {
	GET_ALL_PROJECTS: `projects/GET_ALL_PROJECTS`,
	CREATE_PROJECT: `projects/CREATE_PROJECT`,
	EDIT_PROJECT: `projects/EDIT_PROJECT`,
	DELETE_PROJECT: `projects/DELETE_PROJECT`
};

const projectsActions = {
	[ACTIONS.GET_ALL_PROJECTS]({ commit }, params) {
    commit(PROJECTS_MUTATIONS.SET_LOADING, true);

    return projectsService.getAllProjects(params)
      .then(({ data }) => {
        const { data: projects, totalCount } = data;

        commit(PROJECTS_MUTATIONS.SET_ALL_PROJECTS, { projects, totalCount });
      })
      .catch((error) => {
        commit(PROJECTS_MUTATIONS.SET_ERROR, error);
      });
  },
	[ACTIONS.CREATE_PROJECT]({ commit }, {params, val}) {
		return projectsService.createProject(params, val)
		.then(() => {
			commit(PROJECTS_MUTATIONS.SET_LOADING, true);

			return projectsService.getAllProjects(params);
		})
		.then(({ data }) => {
			const { data: projects, totalCount } = data;

			commit(PROJECTS_MUTATIONS.SET_ALL_PROJECTS, { projects, totalCount });
		})
		.catch((error) => {
			commit(PROJECTS_MUTATIONS.SET_ERROR, error);
		});
  },
	[ACTIONS.EDIT_PROJECT]({ commit }, {params, val}) {
		return projectsService.editProject(params, val)
		.then(() => {
			commit(PROJECTS_MUTATIONS.SET_LOADING, true);

			return projectsService.getAllProjects(params);
		})
		.then(({ data }) => {
			const { data: projects, totalCount } = data;

			commit(PROJECTS_MUTATIONS.SET_ALL_PROJECTS, { projects, totalCount });
		})
		.catch((error) => {
			commit(PROJECTS_MUTATIONS.SET_ERROR, error);
		});
  },
  [ACTIONS.DELETE_PROJECT]({ commit }, {params, val}) {
		return projectsService.deleteProject(params, val)
		.then(() => {
			commit(PROJECTS_MUTATIONS.SET_LOADING, true);

			return projectsService.getAllProjects(params);
		})
		.then(({ data }) => {
			const { data: projects, totalCount } = data;

			commit(PROJECTS_MUTATIONS.SET_ALL_PROJECTS, { projects, totalCount });
		})
		.catch((error) => {
			commit(PROJECTS_MUTATIONS.SET_ERROR, error);
		});
  },
};

export const store = {
  state: projectsState,
  mutations: projectsMutations,
  actions: projectsActions,
};