<template>
  <v-card>
    <v-card-title>
      <h3>{{modalTitle}}</h3>
    </v-card-title>

    <v-card-text class="pb-3">
      <v-layout row wrap>
        <v-flex md12 class="pa-5 pb-0">
          <div class="flex wrap">
            <v-card
              v-for="(comment, key) in comments"
              elevation="2"
              class="comments pa-4 mb-2"
              :key="key"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-row">
                  <p class="text-caption mb-1 font-weight-light">{{ comment.createdBy }}</p>
                  <p class="text-caption ml-3 mb-1 font-weight-light">{{ comment.createdDateTime | formatDate }}</p>
                </div>

                <div v-if="canEdit">
                  <v-icon class="mt-0" @click="deleteComment(comment._id)" v-if="comment._id">mdi-delete-forever</v-icon>
                </div>
              </div>

              <div>{{ comment.text }}</div>
            </v-card>

            <v-textarea
              class="mt-3"
              placeholder="Schrijf hier uw opmerkingen"
              v-model="newComment"
              hide-details
            ></v-textarea>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions class="mr-2 pb-4">
      <v-spacer></v-spacer>

      <v-btn v-if="canEdit" color="primary" @click="addComment">Verzenden</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "comments-dialog",
  props: {
    comments: Array,
    title: String,
    status: Object,
    canEdit: Boolean,
  },
  data: () => ({
    newComment: "",
    modalTitle: ""
  }),
  mounted: function () {
    this.title ? this.modalTitle = this.title : this.modalTitle = "Opmerkingen";

    this.sortedComments;
  },
  computed:{
    sortedComments(){
      const sortedComm = this.comments.sort((a, b) => (a.createdDateTime < b.createdDateTime) ? 1 : ((a.createdDateTime > b.createdDateTime) ? -1 : 0));
      return 	sortedComm;
    }
  },
  methods: {
    addComment() {
      this.$emit("add", this.newComment, this.status);
      this.newComment = ""
    },
    deleteComment(commentId) {
      if (!commentId) {
        return;
      }

      this.$emit("delete", commentId);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
