<template>
	<div>
		<v-layout row wrap>
			<v-flex md12 xs12>
				<v-card class="card pa-4">
					<div class="header d-flex justify-space-between align-center">
						<h1 class="secondary-text text-lg-left form-title">Blijvers</h1>
						<div class="flex pr-0">
							<div class="mt-3 d-flex justify-end">
								<v-btn
									x-large
									color="blue-grey"
									class="white--text"
									@click="download"
								>
									<v-icon small left dark>mdi-file-excel</v-icon>
									Download Blijvers
								</v-btn>
							</div>
						</div>
					</div>

					<v-layout class="mb-5 pr-0 flex justify-end flex-column">
						<div>
							<v-card-title class="d-flex justify-space-between px-0">
								<!-- START FILTER HERE -->
								<filter-data-dialog-component
									class="filter--container"
									:acceptedFilters="acceptedFilters"
									:uniqueFilterStoreName="'filter$stayingList'"
									:showFilters="true"
									:showSearchBar="true"
									@filterData="setFilterOnList">
								</filter-data-dialog-component>
							</v-card-title>
						</div>


						<div class="flex-row d-inline-flex align-center" v-if="canEdit">
							<v-autocomplete class="template-select mr-5" dense outlined hide-details small
								label="Mail template kiezen"
								clearable
								:items="mailTemplates"
								item-text="name"
								item-value="_id"
								v-model="selectedMailTemplate"
								@change="toggleDisableButton"
							></v-autocomplete>
							<v-btn outlined @click="mailSelected()" :disabled="disableMail">
								<v-icon class="mr-2" color="primary">mdi-email-outline</v-icon>
								Onderstaande selectie mailen
							</v-btn>
						</div>
					</v-layout>

					<v-card flat :elevation="0">
						<v-data-table
								:headers="getHeaders"
								:items="tenantsV2"
								:search="search"
								:options.sync="options"
								:server-items-length="totalTenants"
								:footer-props="{'items-per-page-options': [5, 10, 15]}"
								:loading="loading"
								item-key="email"
						>
							<template v-slot:body="{ items }">
								<tbody>
									<tr v-for="item in items">
										<td class="text-xs-left">{{ item.startDate | formatDate }}</td>
										<td class="text-xs-left">{{ item.endDate | formatDate }}</td>
										<td class="text-xs-left">{{ item.project['name'] }}</td>
										<td class="text-xs-left">{{ item.unit.type }}</td>

										<td class="text-xs-left text-center">
											<router-link :to="{ name: 'unitDetail', params: { id: item.unit._id }}" target='_blank'>
												{{ item.unit['name'] }}
											</router-link>
										</td>
										<td class="text-xs-left">
											{{ item.latestStayPrice }}
										</td>
										<td class="text-xs-left">
											{{ item.latestNewPrice}}
										</td>
										<td class="text-xs-left">{{ item.tenant['fullName'] }}</td>
										<td class="text-center">
                      <v-icon :disabled="!canEdit" @click="resetStayLeaveChoice = true; selectedContract = item" v-if="item.stayChoice" :color="getStayLeaveChoiceColor(item.stayChoice)">
                        {{`mdi-${getStayLeaveChoiceIconText(item.stayChoice)}`}}
                      </v-icon>
										</td>
										<td class="text-xs-left">
											<div v-for="stayChoice in tenantFilter.stayChoicesInfo" :key="stayChoice.name">
												<p v-if="stayChoice._id === item.stayChoiceInfo">{{ stayChoice.name }}</p>
											</div>
										</td>
										<td class="text-xs-left px-3">
											<div v-for="status in stayStatusItems" :key="status['_id']">
												<div v-if="status['_id'] === item.contractStatuses">
													<v-select
														:disabled="!canEdit"
														v-model="item.contractStatuses"
														:items="stayStatusItems"
														dense
														solo
														hide-details
														item-text="name"
														item-value="_id"
														@change="StayStatus($event, item._id, item)"
													/>
												</div>
											</div>
										</td>
										<td class="text-center">
											<v-icon v-if="item.comments.length != 0" @click="stayDialogItem = {_id: item._id, item}; stayDialog = true;" color="grey">
												mdi-chat
											</v-icon>
											<v-icon v-else @click="stayDialogItem = {_id: item._id, item}; stayDialog = true;" color="grey">
												mdi-chat-outline
											</v-icon>
										</td>
										<td class="text-center">
											<v-icon @click="goToStayingPage(item)" class="" color="grey">
												mdi-link-variant
											</v-icon>
										</td>
										<!-- <td class="text-center">
											<v-icon @click="mailItem(item)">
												mdi-email-outline
											</v-icon>
										</td> -->
									</tr>
								</tbody>
							</template>
							<template slot="no-data">
								<v-alert :value="true" color="grey" icon="mdi-warn">
									Sorry, geen gebruikers gevonden
								</v-alert>
							</template>
						</v-data-table>
					</v-card>
				</v-card>
			</v-flex>
		</v-layout>
		<v-dialog v-model="stayDialog" max-width="700px">
			<stay-dialog v-if="stayDialog" :item="stayDialogItem" @save="updateRemarks"  @delete="deleteRemark" :canEdit="canEdit"></stay-dialog>
		</v-dialog>

		<v-dialog v-model="createContractDialog" v-if="canEdit && createContractDialog" max-width="700px" scrollable persistent>
			<create-contract-dialog :isLoading="isSavingContract" :extension="true" :item="createContractDialogItem" @save="extendContract" @close="closeContractDetail" :canEdit="getRights.FOLLOW_UP_STAYERS"></create-contract-dialog>
		</v-dialog>

		<v-dialog v-model="endContractDialog" v-if="canEdit && endContractDialog" max-width="700px" scrollable persistent>
			<end-contract-dialog :extension="true" :item="endContractDialogItem" @save="editContract" @close="closeContractDetail"></end-contract-dialog>
		</v-dialog>

    <v-dialog v-model="resetStayLeaveChoice" max-width="300">
      <v-card>
        <v-card-title class="text-h5">
          Keuze resetten
        </v-card-title>
        <v-card-text>
          <p>Wilt u de blijf / vertrek keuze verwijderen van de huurder?</p>
          <p>De huurder kan dan opnieuw zijn of haar keuze maken</p>
        </v-card-text>

        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
                <v-btn
              color="blue darken-1"
              text
              @click="resetStayLeaveChoice = false; selectedContract = null"
          >
            Nee
          </v-btn>
          <v-btn v-if="canEdit" color="blue darken-1" text @click="doResetStayLeaveChoice()">Ja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { ACTIONS } from '../staying.constants';
import { ACTIONS as TOAST_ACTIONS } from '../../../../components/toast/toast.constants';
import JsonToCsv from '../../../../components/vue-json-to-csv/json-to-csv';
import { GETTERS } from '@/account/account.constants';
import { Roles } from '@/account/account.store';
import StayDialog from '@views/admin/staying/components/stay-dialog';
import api from '../../../../services/backendApi';
import createContractDialog from '@views/admin/entries/components/create-contract-dialog';
import endContractDialog from '../components/end-contract-dialog';
import {utf8_to_b64} from '../../../../utils/base64';
import { debounce } from "debounce";
	import moment from 'moment';
  import { DATA_FILTERS } from '@/components/data-filter/const.js';
  import FilterDataDialogComponent from '@components/data-filter/filter-data-dialog.component.vue';


import { FilterInitialValues, FilterValues, StayingListFilters, StayerHeaders, StayerLabels } from './staying-list.const';

const STAYERS_ACCEPTED_FILTERS = [
  DATA_FILTERS.START_DATE,
  DATA_FILTERS.END_DATE,
  DATA_FILTERS.PROJECTS,
  DATA_FILTERS.UNITS,
  DATA_FILTERS.UNIT_TYPES,
  DATA_FILTERS.STAY_CHOICES,
  DATA_FILTERS.STAY_CHOICES_INFO,
  DATA_FILTERS.CONTRACT_STATUSES
  ];

export default {
	components: { FilterDataDialogComponent,StayDialog, JsonToCsv, createContractDialog, endContractDialog },
	props: {
		extended: {
			type: Boolean,
		},
	},
	data: () => ({
    acceptedFilters: STAYERS_ACCEPTED_FILTERS,
    filterParams:{},
    selectedContract: null,
    resetStayLeaveChoice:false,
    isSavingContract:false,
		tenantFilter: null,
		getAllTenants: '',
		tenantsV2: [],
		totalTenants: 0,
		loading: true,
		options: {},
		basicUrl: process.env.VUE_APP_API_URL,
		endpointFilter: null,
		endpointSort: null,
		endpointSearch: null,
		tenantFilter: [],
		stayStatusDefault: 'Onbeantwoord',
		stayStatusItems: [],
		search: '',
		loading: false,
		dialog: false,
		dialogItem: null,
		stayDialog: false,
		stayDialogItem: null,
		createContractDialog: false,
		createContractDialogItem: null,
		endContractDialog: false,
		endContractDialogItem: null,
		file: null,
		selectedUser: null,
		disableMail: true,
		menu: {
			'startDate': false,
			'endDate': false,
		},
		formatedDates: {
			'startDate': null,
			'endDate': null,
		},
		mailTemplates: [],
		selectedMailTemplate: null,
		pagination: {
			sortBy: 'name'
		},
		filters: FilterInitialValues,
		headers: StayerHeaders,
		labels: StayerLabels,
		close: false
	}),
	watch: {
		'filters.startDate'() {
			this.formatedDates.startDate = this.formatDate(this.filters.startDate)
		},
		'filters.endDate'() {
			this.formatedDates.endDate = this.formatDate(this.filters.endDate)
		},
		search: debounce(function(search) {
			this.search = search;
			this.getDataFromApi();
		}, 500),
		options: {
			handler () {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	mounted() {
		const config = { headers: { 'Content-Type': 'application/json' } };
		api
      .get(`${this.basicUrl}/contracts/follow-up/stayers/filter-data`, config)
			.then(response => (
				this.tenantFilter = response.data.data,
				this.stayStatusItems = response.data.data.contractStatuses
			))

		api
      .get(`${this.basicUrl}/mail-templates?minimal=true`, config)
      .then(({ data }) => {
        const { data: templates } = data;

        this.mailTemplates = Array.isArray(templates)
          ? templates
          : [];
      });
	},
	computed: {
		...mapGetters({
			getRole: GETTERS.ROLE,
			getRights: GETTERS.RIGHTS
		}),
		getHeaders() {
			return StayerHeaders
		},
		...mapState({
			staying: state => state.staying.tenants,
		}),
		csvData() {
			if (this.tenantsV2) {
				return this.tenantsV2
			} else {
				console.log("ERROR: if statement - staying-list.vue - r350")
			}
		},
		canEdit() {
			return this.getRights.FOLLOW_UP_STAYERS.toLowerCase() === "edit"
		},
	},
	methods: {
		...mapActions({
			getTenants: ACTIONS.GET_ALL_TENANTS,
			initialStayStatus: ACTIONS.SET_INITIAL_STAY_STATUS,
			makeToast: TOAST_ACTIONS.MAKE_TOAST,
			mailAll: ACTIONS.MAIL_ALL,
			mailPerson: ACTIONS.MAIL_PERSON,
			mailStayersSelection: ACTIONS.MAIL_STAYERS_SELECTION,
			updateStayStatus: ACTIONS.SET_STAYSTATUS_CHECK,
			updateStayRemarks: ACTIONS.UPDATE_REMARKS,
			downloadStayers: ACTIONS.DOWNLOAD,
		}),
    setFilterOnList(filterDataParams){
      this.filterParams = filterDataParams;
      this.getDataFromApi();
    },
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		toggleDisableButton() {
			if (this.selectedMailTemplate) {
				this.disableMail = false;
			} else {
				this.disableMail = true;
			}
		},
		mailSelected() {
      let filter = {};
      const filterPairs = this.getAllTenants.split('&');
      filterPairs.forEach(pair=>{
        const p = pair.split('=');
        filter[p[0]] = p[1];
      });
			const mailTemplate = this.selectedMailTemplate;

			let data = {
				"templateId" : mailTemplate,
				filter: this.filterParams
			}

			return this.mailStayersSelection(data).then(() => {
				this.makeToast({
					message: `Mail werd succesvol verzonden`,
					type: 'success',
				})}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
		mailAllBtn(event) {
			event.currentTarget.disabled = true;
			event.currentTarget.className += ' v-btn--disabled v-btn--flat';
			this.makeToast({ message: "Mails worden verstuurd", type: 'success' });
		},
		goToStayingPage(item) {
			window.open(`${item.extUrl}`, '_blank');
		},
		columnValueList(val) {
			if (this.tenantFilter.projects) {
				let filterNames = [
					{
						filterName: "project.name",
						filterArray: this.tenantFilter.projects
					},
					{
						filterName: "type",
						filterArray: this.tenantFilter.types
					},
					{
						filterName: "unit.name",
						filterArray: this.tenantFilter.units
					},
					{
						filterName: "stayChoice",
						filterArray: this.tenantFilter.stayChoices
					},
					{
						filterName: "stayChoiceInfo",
						filterArray: this.tenantFilter.stayChoicesInfo
					},
					{
						filterName: "contractStatus",
						filterArray: this.tenantFilter.contractStatuses
					}
				]

				for (let index of filterNames) {
					if (val === index.filterName) {
						if (index.filterName) {
							return index.filterArray.map(x => x)
						}
					}
				}
			}
		},
		StayStatus(updateStatus, contractID, statusData) {
      //statusData.status
	  if ( updateStatus === "EXTEND" ) {
        const newStartDate = moment(String(statusData.endDate)).add(1, 'days').format('YYYY-MM-DD');
				this.createContractDialogItem = {...statusData, startDate:newStartDate, endDate:null,newStatus:'EXTEND'};
				this.createContractDialog = true;

				return
			}

			if ( updateStatus === "ENDED" ) {
				this.endContractDialog = true;
				this.endContractDialogItem = {...statusData};

				return
			}

			let data = {
				"status": updateStatus
			}

			api.patch(`${this.basicUrl}/contracts/${contractID}`, data).then(() => {
				this.makeToast({
					message: `Status succesvol aangepast`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
    closeContractDetail() {
			let closeVar;
			let closeVarItem;

			if (this.createContractDialog) {
				closeVar = 'createContractDialog';
				closeVarItem = 'createContractDialogItem';
			} else {
				closeVar = 'endContractDialog';
				closeVarItem = 'endContractDialogItem';
			}

      const cIndex = this.tenantsV2.findIndex((contract)=>contract._id == this[closeVarItem]._id);
      if(cIndex>=0){
        this.tenantsV2[cIndex].contractStatuses = this[closeVarItem].status;
      }
      this[closeVar] = false;
      this[closeVarItem] = null;
    },
		extendContract(val,isLoading) {
      this.isSavingContract = isLoading;
			let data = {
				status: "EXTEND",
				unitId: val.unitId,
				tenantId: val.tenantId,
				startDate: val.startDate,
				endDate: val.endDate,
				price: val.price,
				comment: val.comment,
				fees: {
					rent: val.rent,
					egwi: val.egwi,
					deposit: val.deposit,
					inventory: val.inventory,
					studentTax: val.studentTax,
				}
			}

			api.patch(`${this.basicUrl}/contracts/${val.contractId}/extend`, data).then(() => {
        this.isSavingContract = false;
				this.makeToast({
					message: `Contract werd succesvol verlengd`,
					type: 'success',
				});
				this.createContractDialogItem = null;
				this.createContractDialog = false;
			}, (error) => {
          this.isSavingContract = false;
				this.makeToast({ message: 'Er liep iets mis bij het verlengen van dit contract', type: 'error' });
			})
		},
		editContract(val) {
			api.patch(`${this.basicUrl}/contracts/${val.contractId}`, val).then(() => {
				this.makeToast({
					message: `Contract werd succesvol beeïndigd.`,
					type: 'success',
				});
				this.endContractDialogItem = null;
				this.endContractDialog = false;
			}, (error) => {
				this.makeToast({ message: 'Er liep iets mis bij het aanpassen van de einddatum van dit contract', type: 'error' });
			})
		},
		updateRemarks(val) {
			let data = {
				"text": `${val.comment}`
			}

			api.post(`${this.basicUrl}/contracts/${val._id}/comment`, data).then(() => {
				this.makeToast({
					message: `Comment werd succesvol opgeslagen`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
		deleteRemark(val) {
			api.delete(`${this.basicUrl}/contracts/${val.contractId}/comment/${val.remarkId}`).then(() => {
				this.makeToast({
					message: `Comment werd succesvol verwijderd`,
					type: 'success',
				});
			}, (error) => {
				this.makeToast({ message: error, type: 'error' });
			})
		},
		getDataFromApi() {
			this.loading = true
			this.tenantsCall().then(data => {
				this.tenantsV2 = data.items
				this.totalTenants = data.total
				this.loading = false
			})
		},
		tenantsCall () {
			const config = { headers: { 'Content-Type': 'application/json' } };

			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let sorting = null;
				if (sortBy.length === 1 && sortDesc.length === 1) {
          sorting = sortDesc[0] ? `${sortBy[0]}:1` : `${sortBy[0]}:-1`;
				}

        const params = {
          skip : page-1,
          limit:itemsPerPage,
          sortBy:sorting,
          ... this.filterParams
        };

				const endpoint = `${this.basicUrl}/contracts/follow-up/stayers`;

				api.get(endpoint, {config,params}).then(function (response) {
					let items = response.data.data;
					const total = response.data.totalCount
					resolve({items, total})
				})
			})
		},
		getFilters(event, fetch = true) {
			this.options.page = 1;

			let endpointArr = [];

			for (let index of FilterValues) {
				if (this.filters[index.filterValue] && this.filters[index.filterValue].length > 0) {
					const value = this.filters[index.filterValue];

					let filterData = (typeof value === "string")
						? value
						: value.join(",");

					endpointArr.push(`${index.parameter + filterData}`)
				}
			}

			if (endpointArr && endpointArr.length > 0) {
				let endpointURL = endpointArr.join("")
				endpointURL = endpointURL.substring(1)
				endpointURL = `${endpointURL}&`;
				this.endpointFilter = endpointURL;
			} else {
				this.endpointFilter = null;
			}

			if (fetch) this.getDataFromApi();

			return endpointArr;
		},
		download() {
			const params = this.filterParams;
			const req = this.downloadStayers({params}).then(data => {
				let url = '';

				if (window.URL !== undefined && typeof window.URL === "function") {
					url = window.URL.createObjectURL(new Blob([data]));
				} else {
					const base64 = utf8_to_b64(data);
					url = `data:application/pdf;base64,${base64}`;
				}

				const element = document.createElement('a');

				element.href = url;
				element.setAttribute('download', 'follow-up--stayers.csv');
				document.body.appendChild(element);

				element.click();
				element.remove();
			});

			return req;
		},
    getStayLeaveChoiceColor(choice){
      return this.getStayLeaveIconAndColor(choice).color;
    },
    getStayLeaveChoiceIconText(choice){
      return this.getStayLeaveIconAndColor(choice).icon;
    },
    getStayLeaveIconAndColor(choice){
      const item = {color:'',icon:''};
      switch(choice.toLowerCase()){
        case 'staying':
          item.color = 'green';
          item.icon = 'checkbox-marked-circle-outline';
          return item;
        case 'leaving':
          item.color = 'red';
          item.icon = 'close-circle-outline';
          return item;
        case 'undecided':
          item.color = 'orange';
          item.icon = 'progress-question';
          return item;
        default:
          return item;
      }
    },
    doResetStayLeaveChoice(){
      this.resetStayLeaveChoice = false;
			api.patch(`${this.basicUrl}/contracts/${this.selectedContract._id}`, {resetStayChoice:true}).then(() => {
				this.makeToast({
					message: `Blijf/Vertrek Keuze is verwijderd`,
					type: 'success',
				});
        this.selectedContract.stayChoice = '';
        this.selectedContract.stayChoiceInfo = '';
				this.resetStayLeaveChoice = false;
        this.selectedContract = null;

			}, (error) => {
				this.makeToast({ message: 'Er liep iets mis bij het aanpassen van de blijf/vertrek keuze van dit contract', type: 'error' });

			})
    }
	},
};
</script>

<style lang="scss" scoped>
.filter {
	&--container {
		width: 100%
	}
}

.search-box {
	transform: scale(0.8);
	transform-origin: right;

	.v-text-field {
		padding: 3px;
		margin: 3px;
	}
}

.filter-select {
	max-width: 300px;
}

.container {
	margin-left: 250px;
	margin-right: 250px;
	max-width: calc(100vw - 300px);
}

.form-title {
	display: flex;
}

tr {
	background-color: #ffffff;
}

tr:nth-child(odd) {
	background-color: #f6f6f6;
}
</style>
