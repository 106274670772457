import { PROJECTS_LIST_HEADERS } from '../list/projects-list.const';
import { isEmpty, propOr, pathOr, paths, join } from 'ramda';

/**
 * The mapProjects helper will map the projects from the API to a format that is readable by the data-table.
 *{{ item.comments.length !== 0 ? 'mdi - chat' : 'mdi-chat-outline' }}
 * The PROJECTS_LIST_HEADERS have been defined in ../list/projects-list.const.js to avoid typos and allow for easy tweaks in one place.
 *
 * @param projects these are the provided projects by the API. An array of projects.
 * @returns data-table-items these are the parsed projects in a format that is readable for the data-table.
 */
export default function (projects) {
  if (isEmpty(projects)) {
    return [];
  }

  return projects.map((project) => ({
    [PROJECTS_LIST_HEADERS[0].value]: project, // project data
    [PROJECTS_LIST_HEADERS[1].value]: propOr('-', 'name', project), // project.name
    [PROJECTS_LIST_HEADERS[2].value]: join(' ', paths([['address', 'street'], ['address', 'number']], project) ), // project.address.street
    [PROJECTS_LIST_HEADERS[3].value]: pathOr('-', ['address', 'zip'], project), // project.address.zip
    [PROJECTS_LIST_HEADERS[4].value]: pathOr('-', ['address', 'city'], project), // project.address.city
    _id: projects._id,
  }));
}
