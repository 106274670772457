import {ACTIONS, MUTATIONS} from './staying.constants';
import stayingService from './staying.service';

const staying = {
    state: {
        loading: false,
        tenants: [],
        detailTenant: {}
    },
    actions: {
        [ACTIONS.GET_ALL_TENANTS]({commit}, filter) {
            commit(MUTATIONS.SET_IS_LOADING, true);
            return new Promise((resolve, reject) => {
                stayingService.getTenants(filter)
                    .then((result) => {
                        commit(MUTATIONS.SET_ALL_TENANTS, result.data.tenants);
                        resolve(result.data);
                    }, (error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit(MUTATIONS.SET_IS_LOADING, false);
                    });
            });
        },
        [ACTIONS.SET_INITIAL_STAY_STATUS]({ commit }) {
            commit(MUTATIONS.SET_IS_LOADING, true);
            return new Promise((resolve, reject) => {
                stayingService.initialStayStatus()
                    .then((result) => {
                        commit(MUTATIONS.SET_IS_LOADING, false)
                    }, (error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit(MUTATIONS.SET_IS_LOADING, false);
                    });
            });
        },
        [ACTIONS.SET_STAYSTATUS_CHECK]({ commit, dispatch }, tenant) {
			return new Promise((resolve, reject) => {
                stayingService.updateStayStatus(tenant)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_TENANTS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
        },
        [ACTIONS.UPDATE_REMARKS]({ commit, dispatch }, tenant) {
			return new Promise((resolve, reject) => {
                stayingService.updateStayRemarks(tenant)
					.then((result) => {
						dispatch(ACTIONS.GET_ALL_TENANTS);
						resolve(result.data);
					}, (error) => {
						reject(error);
					});
			});
		},
        [ACTIONS.REMOVE_REMARK]({ commit, dispatch }, {
            tenantId,
            remarkId,
        }) {
            return new Promise((resolve, reject) => {
                stayingService.removeStayRemarks(tenantId, remarkId)
                    .then((result) => {
                        dispatch(ACTIONS.GET_ALL_TENANTS);
                        resolve(result.data);
                    }, (error) => {
                        reject(error);
                    });
            });
        },
        [ACTIONS.MAIL_PERSON]({commit}, data) {
            commit(MUTATIONS.SET_IS_LOADING, true);
            return new Promise((resolve, reject) => {
                stayingService.mailPerson(data)
                    .finally(() => {
                        commit(MUTATIONS.SET_IS_LOADING, false);
                    });
            });
        },
        [ACTIONS.MAIL_ALL]({commit}) {
            commit(MUTATIONS.SET_IS_LOADING, true);
            return new Promise((resolve, reject) => {
                stayingService.mailAll().finally(() => {
                    commit(MUTATIONS.SET_IS_LOADING, false);
                });
            });
        },
        [ACTIONS.GET_DETAIL_TENANT]({commit}, id) {
            commit(MUTATIONS.SET_IS_LOADING, true);
            return new Promise((resolve, reject) => {
                stayingService.getTenantDetail(id)
                    .then((result) => {
                        commit(MUTATIONS.SET_DETAIL_TENANT, result.data);
                        resolve(result.data);
                    }, (error) => {
                        reject(error);
                    })
                    .finally(() => {
                        commit(MUTATIONS.SET_IS_LOADING, false);
                    });
            });
        },
        [ACTIONS.DOWNLOAD]({commit}, {params}) {
            return new Promise((resolve, reject) => {
                stayingService.downloadStayers(params).then(
                    result => resolve(result.data),
                    error => reject(error)
                );
            });
        },
        [ACTIONS.MAIL_STAYERS_SELECTION]({commit}, data) {
			commit(MUTATIONS.SET_IS_LOADING, true);
			return new Promise((resolve, reject) => {
					stayingService.mailStayersSelection(data)
					.then((result) => {
						resolve(result.data);
					}, (error) => {
						reject(error);
					})
			});
        }
    },
    mutations: {
        [MUTATIONS.SET_ALL_TENANTS](state, tenants) {
            state.tenants = tenants;
        },
        [MUTATIONS.SET_DETAIL_TENANT](state, tenant) {
            state.detailTenant = tenant;
        },
        [MUTATIONS.SET_IS_LOADING](state, loadingState) {
            state.loading = loadingState;
        },
    },
};


export default staying;
