import {ACTIONS,MUTATIONS} from '../tenant.const';
import {tenantService} from '../services/tenant.service';

import { isEmpty, isNil } from 'ramda';

const tenantInitialState = {
    tenants:[],
    currentTenant:null,
    currentTenantContracts:[],
    currentTenantSelectedContract:null,
    totalCount: 0,
    filterData: null,
    isLoading: false,
    error: null,
};

export const tenantMutations = {
    [MUTATIONS.SET_LOADING](state, isLoading) {
        state.isLoading = isLoading;
      },
    [MUTATIONS.SET_ERROR](state, error) {
        state.error = error;
        state.isLoading = false;
      },
    [MUTATIONS.SET_ALL_TENANTS](state, { tenants, totalCount }) {
        state.tenants = isNil(tenants) || isEmpty(tenants)
          ? []
          : tenants;
        state.totalCount = totalCount;
        state.isLoading = false;
        state.error = null;
      },
    [MUTATIONS.SET_DETAIL_TENANT](state, { currentTenant }) {
        state.currentTenant = isNil(currentTenant) || isEmpty(currentTenant)
          ? null
          : currentTenant;
        state.isLoading = false;
        state.error = null;
      },
    [MUTATIONS.SET_TENANT_CONTRACTS](state, { currentTenantContracts }) {
        state.currentTenantContracts = isNil(currentTenantContracts) || isEmpty(currentTenantContracts)
          ? null
          : currentTenantContracts;
        state.isLoading = false;
        state.error = null;
      },
    [MUTATIONS.SET_TENANT_SELECTED_CONTRACT](state, { currentTenantSelectedContract }) {
        state.currentTenantSelectedContract = isNil(currentTenantSelectedContract) || isEmpty(currentTenantSelectedContract)
          ? null
          : currentTenantSelectedContract;
        state.isLoading = false;
        state.error = null;
      },
};

const tenantActions = {
    [ACTIONS.GET_ALL_TENANTS]({commit},params){
        commit(MUTATIONS.SET_LOADING, true);
        return tenantService.getTenants(params)
            .then(({data})=>{
                const { data: tenants, totalCount } = data;
                commit(MUTATIONS.SET_ALL_TENANTS, { tenants, totalCount });
            })
            .catch((error)=>{
                commit(MUTATIONS.SET_ERROR,error);
            })
    },
    [ACTIONS.GET_DETAIL_TENANT]({commit},tenantId){
      commit(MUTATIONS.SET_LOADING, true);
      return tenantService.getOneTenant(tenantId)
          .then(({data})=>{
            const { data: currentTenant } = data;
              commit(MUTATIONS.SET_DETAIL_TENANT, {currentTenant});
          })
          .catch((error)=>{
              commit(MUTATIONS.SET_ERROR,error);
          })
    },
    [ACTIONS.GET_TENANT_CONTRACTS]({commit},tenantId){
      commit(MUTATIONS.SET_LOADING, true);
      return tenantService.getContractsForTenant(tenantId)
          .then(({data})=>{
            const { data: currentTenantContracts } = data;
              commit(MUTATIONS.SET_TENANT_CONTRACTS, {currentTenantContracts});
          })
          .catch((error)=>{
              commit(MUTATIONS.SET_ERROR,error);
          })
    },
    [ACTIONS.GET_TENANT_SELECTED_CONTRACT]({commit},{tenantId,contractId}){
      commit(MUTATIONS.SET_LOADING, true);
      return tenantService.getContractForTenant(tenantId,contractId)
          .then(({data})=>{
            const { data: currentTenantSelectedContract } = data;
              commit(MUTATIONS.SET_TENANT_SELECTED_CONTRACT, {currentTenantSelectedContract});
          })
          .catch((error)=>{
              commit(MUTATIONS.SET_ERROR,error);
          })
    }
}

export const store = {
    state: tenantInitialState,
    mutations: tenantMutations,
    actions: tenantActions,
  };