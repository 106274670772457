<template>
<v-container grid-list-md text-xs-center class="container">
	<v-layout row wrap>
      <v-flex md12 xs12 class="">
		<v-card class="card pa-4">
			<div class="header-container">
            <div class="header d-flex justify-space-between align-center">
              <div>
                <h1 class="secondary-text text-lg-left form-title">Tenants</h1>
              </div>
              <div>
				<!--
                <div class="mt-3 d-flex justify-end">
                  <v-btn
                    x-large
                    color="blue-grey"
                    class="white--text"
                    @click="download"
                  >
                    <v-icon small left dark>mdi-file-excel</v-icon>
                    Download Tenants
                  </v-btn>
                </div>

				-->
              </div>
            </div>
          </div>

		  <v-card flat :elevation="0">
			<v-card-title class="d-flex justify-space-between px-0">
              <!-- START FILTER HERE -->
              <filter-data-dialog-component
								class="filter--container"
                :searchLabel="'Zoeken'"
								:acceptedFilters="acceptedFilters"
                :uniqueFilterStoreName="'filter$tenantList'"
                @filterData="setFilterOnList"
                :showFilters="false"
				:showSearchBar="true"
                >
              </filter-data-dialog-component>
            </v-card-title>
			<v-data-table
			item-key="tableKey"
              :headers="headers"
              :menu="menu"
              :items="tenants"
              :options.sync="options"
              :server-items-length="totalCount"
              :loading="isLoading"
              :footer-props="{'items-per-page-options': [5, 10, 15]}"
				@update:page="setSkip"
              	@update:items-per-page="setLimit"
              	@update:sort-by="setSort"
              	@update:sort-desc="setSort"
				@click:row="clickItem"
			>

			</v-data-table>
				<v-card-title>
					<div class="px-3">
						<v-select class="filter-select" dense outlined hide-details small multiple
						          label="Jaar"
						          clearable
											:items="columnValueList('year')"
						          v-model="filters.year">
						</v-select>
					</div>
					<v-spacer></v-spacer>
					<v-text-field
							class="search-box"
							v-model="search"
							append-icon="mdi-search"
							label="Search"
							single-line
							filled
							hide-details
					></v-text-field>
				</v-card-title>

				<v-data-table
						:headers="getHeaders"
						:items="filteredTenants"
						:search="search"
						:custom-sort="customSort"
						item-key="email"
				>
					<template v-slot:header="{ props }">
						<tr class="grey lighten-3">
							<th
									v-if="props.headers"
									v-for="header in props.headers"
									:key="header.text"
							>
								<div v-if="filters.hasOwnProperty(header.value)" class="px-3">
									<v-autocomplete v-if="!header.value.includes('Date')" class="filter-select" dense outlined hide-details
									          small multiple
									          clearable :items="columnValueList(header.value)"
									          v-model="filters[header.value]">
									</v-autocomplete>
									<v-menu v-else :ref="menu[header.value]" v-model="menu[header.value]" :close-on-content-click="false"
									        transition="scale-transition" offset-y min-width="290px">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
													:value="formatedDates[header.value]"
													prepend-inner-icon="mdi-calendar"
													readonly
													dense outlined
													hide-details small
													v-bind="attrs"
													v-on="on"
													@input="filters[header.value] = null"
													clearable
											></v-text-field>
										</template>
										<v-date-picker
												v-model="filters[header.value]"
												no-title
												scrollable
										>
										</v-date-picker>
									</v-menu>
								</div>
							</th>
						</tr>
					</template>
					<template v-slot:body="{ items }">
						<tbody>
						<tr v-for="item in items">
							<td class="text-xs-left" v-if="!extended">{{ item.tenantId }}</td>
							<td class="text-xs-left">{{ item.startDate | formatDate }}</td>
							<td class="text-xs-left">{{ item.endDate | formatDate }}</td>
							<td class="text-xs-left">{{ item.unitId.project }}</td>
							<td class="text-center">
								<router-link :to="{ name: 'unitDetail', params: { id: item.unitId._id }}" target='_blank'>
									{{ item.unitId.roomNumber }}
								</router-link>
							</td>
							<td class="text-xs-left">{{ item.language }}</td>
							<td class="text-xs-left">{{ item.name }}</td>
							<td class="text-xs-left">{{ item.email }}</td>
							<td class="justify-center" v-if="extended" v-for="payment in payments">
								<template v-if="showBullet(item.unitId, item.year, payment.id)">
									<v-icon v-if="item[payment.id] && item[payment.id].checked"
									        @click="paymentDialogItem = {_id: item._id, payment}; paymentDialog = true"
									        class="mr-2" color="green">
										mdi-checkbox-marked-circle-outline
									</v-icon>
									<v-icon v-else-if="item[payment.id] && item[payment.id].status"
									        @click="paymentDialogItem = {_id: item._id, payment}; paymentDialog = true"
									        class="mr-2" color="orange">
										mdi-alert-circle-outline
									</v-icon>
									<v-icon v-else class="mr-2" color="red"
									        @click="paymentDialogItem = {_id: item._id, payment}; paymentDialog = true">
										mdi-close-circle-outline
									</v-icon>
								</template>
							</td>
							<td class="justify-center" v-if="extended">
								<v-icon v-if="item.imgFront && !item.passportChecked"
								        @click="dialogItem = item; dialog = true"
								        class="mr-2" color="orange">
									mdi-alert-circle-outline
								</v-icon>
								<v-icon v-else-if="item.imgFront && item.passportChecked"
								        @click="dialogItem = item; dialog = true"
								        class="mr-2" color="green">
									mdi-checkbox-marked-circle-outline
								</v-icon>
								<v-icon v-else class="mr-2" color="red">
									mdi-close-circle-outline
								</v-icon>
							</td>
              <td class="text-center">
                <v-icon v-if="item.unitId.contractRemarksStatus" @click="commentContractDialogItem = item; commentContractDialog = true;" color="grey">
                  mdi-chat
                </v-icon>
                <v-icon v-else @click="commentContractDialogItem = item; commentContractDialog = true;" color="grey">
                  mdi-chat-outline
                </v-icon>
              </td>
							<td class="justify-center" v-if="extended">
								<v-icon @click="goToPaymentPage(item)" class="mr-2" color="grey">
									mdi-link-variant
								</v-icon>
							</td>
							<td class="justify-center" v-if="extended">
								<v-icon @click="mailItem(item)">
									mdi-email-outline
								</v-icon>
							</td>
							<td class="justify-center" v-if="canEdit && !extended">
								<v-icon @click="MaillogDialog = true, MaillogDialogItem = {logs: item.mailLog, email: item.email}" v-if="item.mailLog">
									mdi-email-outline
								</v-icon>
							</td>
							<td class="justify-center" v-if="canEdit && !extended">
								<v-icon @click="confirmDeleteDialogItem = item; confirmDeleteDialog = true" class="mr-2" color="grey">
									mdi-delete
								</v-icon>
							</td>
						</tr>
						</tbody>
					</template>
					<template slot="no-data">
						<v-alert :value="true" color="grey" icon="mdi-warn">
							Sorry, geen gebruikers gevonden
						</v-alert>
					</template>
				</v-data-table>

				<v-file-input
						v-if="canEdit"
						outlined
						class="file-input"
						prepend-inner-icon="mdi-paperclip"
						prepend-icon=""
						accept="text/comma-separated-values"
						placeholder="bestand uploaden"
						v-model="file"
				></v-file-input>
				<v-btn v-if="canEdit" outlined :loading="loading" @click="uploadFile">bestand opladen (vanuit Omnibox)</v-btn>

				<v-btn outlined class="ml-3" @click="createTenantDialog = true">
					<v-icon class="mr-2" color="primary">mdi-account-plus</v-icon>
					Creatie huurder
				</v-btn>

			</v-card>
		</v-card>
	  </v-flex>
	</v-layout>
</v-container >
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { isNil, isEmpty, pathOr } from "ramda";
import { ACTIONS as TOAST_ACTIONS } from "../../../../components/toast/toast.constants.js";
import {ACTIONS,TENANT_LIST_HEADERS,INITIAL_FILTERS,FILTER_SORT_OPTIONS} from "../tenant.const";
import FilterDataDialogComponent from '@components/data-filter/filter-data-dialog.component.vue';


	export default {
		components:{
			FilterDataDialogComponent
		},
		data: ()=>({
			acceptedFilters: [],
			filterParams:{},
			headers: TENANT_LIST_HEADERS,
			currentFilters: INITIAL_FILTERS,
			options:{},
			menu: {
			'betweenRange': false,
		},
		}),
		watch: {
			error: function () {
			if (!isNil(this.error)) {
				this.makeToast({
				message: "Er ging iets fout bij het ophalen van de data.",
				type: "error",
				});
			}
			},
		},
		mounted: function (){},
		computed : {
			...mapState({
				tenants: (state) => pathOr([], ["tenants", "tenants"], state),
				isLoading: (state) => pathOr(false, ["tenants", "isLoading"], state),
				totalCount: (state) => pathOr(0, ["tenants", "totalCount"], state),
				error: (state) => pathOr(null, ["tenants", "error"], state),
    		}),
		},
		methods :{
			...mapActions({
				makeToast: TOAST_ACTIONS.MAKE_TOAST,
				getTenants: ACTIONS.GET_ALL_TENANTS,
			}),
			setFilterOnList(filterDataParams){
				this.filterParams = filterDataParams;

			   	if(filterDataParams.textSearch){
			    	this.resetSkip();
			   	}
				this.searchTenants();
   			},
			resetSkip: function () {
				this.options.page = 1;
				this.currentFilters.skip = 0;
			},
			setSkip: function () {
      			this.currentFilters.skip = this.options.page -1;
      			this.searchTenants();
    		},
    		setLimit: function () {
      			this.currentFilters.limit = this.options.itemsPerPage;
      			this.searchTenants();
    		},
			setSort: function () {
				const selectedSort = this.options.sortBy[0];
				const sortIsDesc = this.options.sortDesc[0];

				if (isNil(selectedSort)) {
					this.currentFilters.sortBy = null;
					this.searchTenants();
					return;
				}

				this.currentFilters.sortBy = sortIsDesc
					? (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:1`)
					: (this.currentFilters.sortBy = `${FILTER_SORT_OPTIONS[selectedSort]}:-1`);

				this.searchTenants();
			},
			clickItem: function(item){
				console.log(item)
				//params: { id: item._id }
				this.$router.push({ name: `tenantDetail`,params:{id:item._id} });
			},
			searchTenants: function (){
				const params = {
					skip : this.currentFilters.skip,
					limit:this.currentFilters.limit,
					sortBy:this.currentFilters.sortBy,
					... this.filterParams
				};

				this.getTenants(params)
			}
		}
	}
</script>

<style lang="scss" scoped>

@import 'src/styles/global-filter.scss';

.search {
	max-width: 40rem;
	margin: 0 1rem 0 0;
}

.search-box {
	transform: scale(0.8);
	transform-origin: right;

	.v-text-field {
		padding: 3px;
		margin: 3px;
	}
}

.filter-select {
	max-width: 300px;
}

.container {
	margin: 0 15.625rem;
	max-width: calc(100vw - 300px);
}

.form-title {
	display: flex;
}

tr {
	background-color: #ffffff;
}

tr:nth-child(odd) {
	background-color: #f6f6f6;
}


</style>
